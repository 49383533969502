import { Form, App } from 'antd';
import { useMeetingAgendaItem } from 'hooks/meetings';
import { nextOrder } from 'pages/teams/cards/meetings/utils';

import type { AgendaItem, CreateAgendaItem, Meeting, MeetingColumn, UpdateAgendaItem } from 'types';
import { getErrorMessage } from 'utils';
import NameFormItem from 'components/forms/common/NameFormItem';
import DescriptionFormItem from 'components/forms/common/DescriptionFormItem';
import FormButtonBar from 'components/forms/common/FormButtonBar';
import DeleteAgendaItemButton from 'components/buttons/DeleteAgendaItemButton';
import { createAgendaItem, updateAgendaItem } from 'services/meetings';
import { useOrg } from 'hooks/useOrg';

type AgendaItemFormFields = Pick<AgendaItem, 'name' | 'description'>;

type AgendaItemFormBaseProps = {
    meeting: Meeting;
    onFinish?: (agendaItem: AgendaItem) => void;
    onCancel?: () => void;
};

type AgendaItemFormCreateProps = AgendaItemFormBaseProps & {
    column: MeetingColumn;
};

type AgendaItemFormUpdateProps = AgendaItemFormBaseProps & {
    agendaItem: AgendaItem;
    onDelete?: (agendaItem: AgendaItem) => void;
};

type AgendaItemFormProps = AgendaItemFormCreateProps | AgendaItemFormUpdateProps;

const AgendaItemForm = (props: AgendaItemFormProps) => {
    const { message } = App.useApp();
    const { addMeetingAgendaItem, updateMeetingAgendaItem } = useMeetingAgendaItem(props.meeting);

    const org = useOrg();
    if (!org) return null;

    const isEdit = !!(props as AgendaItemFormUpdateProps).agendaItem;

    const onCreate = async (values: AgendaItemFormFields) => {
        const createProps = props as AgendaItemFormCreateProps;
        try {
            const createRequest: CreateAgendaItem = {
                ...values,
                meeting_column_id: createProps.column.id,
                n: nextOrder(createProps.column.items),
            };
            const agendaItem = await createAgendaItem(org, createRequest);
            addMeetingAgendaItem(agendaItem);
            props.onFinish?.(agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    const onEdit = async (values: AgendaItemFormFields) => {
        const updateProps = props as AgendaItemFormUpdateProps;
        try {
            const agendaItem = await updateAgendaItem(org, updateProps.agendaItem.id, {
                ...updateProps.agendaItem,
                ...values,
            } as UpdateAgendaItem);
            updateMeetingAgendaItem(agendaItem);
            updateProps.onFinish?.(agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    const onFinish = async (values: AgendaItemFormFields) => {
        return isEdit ? onEdit(values) : onCreate(values);
    };

    return (
        <Form layout={'vertical'} initialValues={(props as AgendaItemFormUpdateProps).agendaItem} onFinish={onFinish}>
            <NameFormItem />
            <DescriptionFormItem />

            <FormButtonBar
                onCancel={props.onCancel}
                extra={
                    isEdit ? (
                        <DeleteAgendaItemButton {...(props as AgendaItemFormUpdateProps)}>
                            Mark Done
                        </DeleteAgendaItemButton>
                    ) : null
                }
            />
        </Form>
    );
};

export default AgendaItemForm;
