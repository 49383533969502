import React from 'react';
import { App, Button, Popconfirm } from 'antd';
import { DeleteOutlined, ExclamationOutlined } from '@ant-design/icons';

import type { Meeting } from 'types';
import { getErrorMessage } from 'utils';
import { useOrg } from 'hooks/useOrg';
import { deleteMeeting } from 'services/meetings';

const DeleteTeamMeetingButton = (props: {
    meeting: Meeting;
    onDelete?: (meeting: Meeting) => void;
    onCancel?: (meeting: Meeting) => void;
}) => {
    const { message } = App.useApp();
    const org = useOrg();

    const onConfirm = async () => {
        try {
            await deleteMeeting(org!, props.meeting);
            props.onDelete?.(props.meeting);
            message.success('Meeting deleted successfully.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    return (
        <Popconfirm
            title={'Are you sure you want to delete this meeting?  This action cannot be undone.'}
            icon={<ExclamationOutlined style={{ color: '#FF4D4F' }} />}
            onConfirm={onConfirm}
            onCancel={() => props.onCancel?.(props.meeting)}
            okButtonProps={{ type: 'default', danger: true }}
        >
            <Button type={'link'} icon={<DeleteOutlined />} size={'small'} />
        </Popconfirm>
    );
};

export default DeleteTeamMeetingButton;
