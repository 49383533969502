import { useContext } from 'react';

import { FieldContext, type FieldContextProps } from 'providers/FieldsProvider';

export function useFieldsState(): [FieldContextProps['fields'], FieldContextProps['setFields']] {
    const context = useContext(FieldContext);
    return [context.fields, context.setFields];
}

export function useFields() {
    const context = useContext(FieldContext);
    return context.fields;
}
