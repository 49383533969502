import React from 'react';
import { useNavigate } from 'react-router-dom';

import SettingsCreateButton from 'pages/settings/_common/SettingsCreateButton';

const CreateMeetingTypeButton = () => {
    const navigate = useNavigate();
    return (
        <SettingsCreateButton onClick={() => navigate('/settings/create-meeting-type')}>
            Create Meeting Type
        </SettingsCreateButton>
    );
};

export default CreateMeetingTypeButton;
