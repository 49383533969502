import { useEffect, useState } from 'react';

export function useHash() {
    const [hash, setHash] = useState(window.location.hash);

    const removeHash = (append: boolean = false) => {
        if (append) {
            window.history.pushState('', document.title, window.location.pathname + window.location.search);
        } else {
            window.history.replaceState('', document.title, window.location.pathname + window.location.search);
        }
        setHash('#');
    };

    useEffect(() => {
        const handleHashChange = () => {
            setHash(window.location.hash);
        };

        window.addEventListener('hashchange', handleHashChange);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return { hash: hash.substring(1), removeHash };
}
