import { useTeam, useTeams } from 'hooks/teams';
import { useZones } from 'hooks/useZones';
import { SearchResult, Team } from 'types';

const BaseSearchLabel = (props: { result: SearchResult }) => {
    return <div>{props.result.name}</div>;
};

const TeamSearchLabel = (props: { result: SearchResult }) => {
    const zones = useZones();
    const team = useTeam(props.result.id);
    const zone = zones.find((z) => z.id === team?.zone.id);

    return zone ? (
        <div>
            {props.result.name} in {zone.name}
        </div>
    ) : (
        <BaseSearchLabel result={props.result} />
    );
};

const TeamObjectSearchLabel = (props: { result: SearchResult }) => {
    const teams = useTeams();
    const team = props.result.team_id
        ? teams.find<Team>((t: Team): t is Team => t.id === props.result.team_id)
        : undefined;

    return team ? (
        <div>
            {props.result.name} in {team.name}
        </div>
    ) : (
        <BaseSearchLabel result={props.result} />
    );
};

const SearchLabel = (props: { result: SearchResult }) => {
    switch (props.result.type) {
        case 'team':
            return <TeamSearchLabel result={props.result} />;
        case 'decision':
        case 'objective':
        case 'role':
            return <TeamObjectSearchLabel result={props.result} />;
    }
    return <BaseSearchLabel result={props.result} />;
};

export default SearchLabel;
