import React from 'react';
import Dropzone from 'react-dropzone';

import styles from './styles.module.scss';

const AvatarDropzone = (props: { onDrop?: (file: File) => void }) => {
    function onDrop(acceptedFiles: File[]) {
        if (acceptedFiles.length > 0) {
            props.onDrop?.(acceptedFiles[0]);
        }
    }

    return (
        <div className={styles.dropzone}>
            <Dropzone
                accept={{
                    'image/png': ['.png'],
                    'image/jpg': ['.jpg', '.jpeg'],
                }}
                onDrop={onDrop}
                onError={(error) => console.log(error)}
                onDropRejected={() => console.log('Drop rejected.')}
                maxFiles={1}
                maxSize={2 * 1024 * 1024}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Click, or drop an image file here.</p>
                        </div>
                    </section>
                )}
            </Dropzone>
            <small>JPG or PNG, maximum 2MB.</small>
        </div>
    );
};

export default AvatarDropzone;
