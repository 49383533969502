import React from 'react';
import { Form, type FormItemProps, Select } from 'antd';

import { selectOptions } from 'utils';
import { usePeople } from 'hooks/usePeople';

const PersonFormItem = (props: {
    label: string;
    name?: FormItemProps['name'];
    required?: boolean;
    personId?: string;
    omitIds?: string[];
    setPersonId?: (personId: string | undefined) => void;
    allowClear?: boolean;
}) => {
    const people = usePeople();
    const options = selectOptions(people || [], props.omitIds);

    return (
        <Form.Item
            className={'form-control'}
            label={props.label}
            name={props.name}
            rules={[{ required: props.required, message: 'Please enter a value.' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Select
                showSearch
                placeholder="Please select"
                optionFilterProp={'label'}
                defaultValue={props.personId}
                onChange={props.setPersonId}
                options={options}
                allowClear={props.allowClear}
            />
        </Form.Item>
    );
};

export default PersonFormItem;
