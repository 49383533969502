import { useState } from 'react';
import { App } from 'antd';

import { Decision, Team } from 'types';
import { AppDispatch, useAppDispatch } from 'store';

import { createDecision } from 'features/decisionSlice';
import { getDispatchError, hasDispatchError } from 'utils';
import GeneralSteps from 'components/steps/common/GeneralSteps';

const CreateDecisionSteps = (props: { team?: Team; onSuccess: (decision: Decision) => void; onCancel: () => void }) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const [decision, setDecision] = useState(() => {
        const decision: Decision = { type: 'decision' } as Decision;
        if (props.team) {
            decision.team = { type: 'team', id: props.team.id };
        }
        return decision;
    });
    const [loading, setLoading] = useState(false);

    async function onCreate() {
        setLoading(true);
        const newDecision = {
            ...decision,
        };
        if (props.team) {
            newDecision.team = { id: props.team.id, type: 'team' };
        }

        const res = await dispatch(createDecision(newDecision as Decision));
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your decision was successfully created.');
        props.onSuccess(res.payload as Decision);
    }

    /* Set team */
    const skip = props.team ? ['team'] : undefined;

    return (
        <GeneralSteps
            obj={decision}
            setObj={setDecision}
            onFinish={onCreate}
            onCancel={props.onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateDecisionSteps;
