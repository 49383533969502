import { ChangeEvent } from 'react';
import { Form, Input } from 'antd';
import { isUndefined } from 'utils';

type NameFormItemProps = {
    name: string;
};

const NameFormItem = (
    props: Partial<NameFormItemProps> & {
        onChange?: (name: string) => void;
    },
) => {
    const isManaged = isUndefined(props.onChange);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e.target.value);
    };

    return (
        <Form.Item<NameFormItemProps>
            className={'form-control'}
            label={'Name'}
            name={isManaged ? 'name' : undefined}
            rules={[{ required: true, message: 'Please enter a descriptive name.' }]}
            initialValue={isManaged ? props.name : undefined}
            required={true}
        >
            <Input value={isManaged ? undefined : props.name} onChange={isManaged ? undefined : onChange} />
        </Form.Item>
    );
};

export default NameFormItem;
