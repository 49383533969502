import ObjectForm from 'components/forms/ObjectForm';
import type { TemplatedObject } from 'types';

const ObjectDrawerEdit = (props: {
    obj: TemplatedObject;
    onFinish?: (obj: TemplatedObject) => void;
    onCancel?: () => void;
}) => <ObjectForm obj={props.obj} onSave={props.onFinish} onCancel={props.onCancel} />;

export default ObjectDrawerEdit;
