import { useElementSize } from '@custom-react-hooks/use-element-size';

import type { Team, Role, ThemeMode, TemplatedObject } from 'types';

import styles from './styles.module.scss';
import { useTeamGraphData } from 'hooks/useData';
import { useThemeMode } from 'hooks/useThemeMode';

import Loading from 'components/Loading';
import ForceDirectedGraph from 'components/canvas/fdg/ForceDirectedGraph';
import { FDGLink, FDGNode } from 'components/canvas/fdg/types';
import TeamGraphLegend from './legend/TeamGraphLegend';

function fdgData(team: Team, roles: Role[] | undefined, mode: ThemeMode) {
    const nodes: Record<string, FDGNode> = {};
    nodes[team.id] = {
        id: team.id,
        fillStyle: styles.teamColorFill,
        strokeStyle: styles.teamColorStroke,
        obj: team,
        radius: 30,
    };

    const links = [] as FDGLink[];

    if (roles) {
        for (const role of roles) {
            //const user = role.assignment?.id ? people[role.assignment.id] : undefined;
            const fillStyle = role.assignment_id
                ? styles.roleColorFill
                : mode === 'dark'
                  ? styles.darkBg
                  : styles.lightBg;
            nodes[role.id] = { id: role.id, fillStyle: fillStyle, strokeStyle: styles.roleColorStroke, obj: role };
        }

        for (const role of roles) {
            links.push({
                source: nodes[role.id],
                target: nodes[team.id],
            });
        }
    }

    return {
        nodes: Object.keys(nodes).map((key) => nodes[key]),
        links,
    };
}

const TeamGraph = (props: { team: Team; onClick?: (obj: TemplatedObject) => void }) => {
    const mode = useThemeMode();
    const [setRef, size] = useElementSize();
    const objs = useTeamGraphData(props.team);
    const data = fdgData(props.team, objs?.roles, mode);

    if (!objs) {
        return <Loading />;
    }

    function onTooltipText(node: FDGNode | undefined) {
        return node ? node.obj.name : undefined;
    }

    return (
        <div ref={setRef} className={styles.container}>
            {size.width && size.height ? (
                <ForceDirectedGraph
                    data={data}
                    onTooltipText={onTooltipText}
                    onClick={(node) => props.onClick?.(node.obj as TemplatedObject)}
                />
            ) : null}
            <TeamGraphLegend />
        </div>
    );
};

export default TeamGraph;
