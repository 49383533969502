import React from 'react';
import { Form, type FormItemProps, Input } from 'antd';

import type { DataType } from './EditableDnDTable';

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'text';
    record: DataType;
    index: number;
    validator?: (cell: { dataIndex: string; value: string }) => Promise<void>;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = (props) => {
    const { editing, dataIndex, title, inputType, record, index, children, validator, ...restProps } = props;

    const rules: FormItemProps['rules'] = [
        {
            required: true,
            message: 'required',
        },
    ];

    if (validator) {
        rules.push({ validator: (_, value: string) => validator({ dataIndex, value }) });
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
