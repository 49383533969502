import SettingsAction from 'pages/settings/_common/SettingsAction';
import React, { useState } from 'react';
import { App } from 'antd';

import { useOrg } from 'hooks/useOrg';
import { Field } from 'types';
import EditFieldModal from '../edit/EditFieldModal';
import { updateField } from 'services/fields';
import { getErrorMessage } from 'utils';

const EditFieldButton = (props: { field: Field; onFinish?: (field: Field) => void }) => {
    const org = useOrg();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { message } = App.useApp();

    if (!org) return null;

    const onSave = async (field: Field) => {
        try {
            const newField = await updateField(org, field);
            setIsModalOpen(false);
            props.onFinish?.(newField);
            message.success('Field created.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    const onCancel = () => setIsModalOpen(false);

    return (
        <>
            <SettingsAction type={'success'} onClick={() => setIsModalOpen(true)}>
                Edit
            </SettingsAction>
            <EditFieldModal
                title={'Edit Field'}
                field={props.field}
                open={isModalOpen}
                onCancel={onCancel}
                onSave={onSave}
            />
        </>
    );
};

export default EditFieldButton;
