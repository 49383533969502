import React from 'react';

import SettingsCreateButton from 'pages/settings/_common/SettingsCreateButton';
import { useNavigate } from 'react-router-dom';

const CreateTemplateButton = () => {
    const navigate = useNavigate();

    return (
        <>
            <SettingsCreateButton onClick={() => navigate('/settings/templates/create')}>
                Create Template
            </SettingsCreateButton>
        </>
    );
};

export default CreateTemplateButton;
