import { useCurrentUser } from 'hooks/useCurrentUser';
import React, { CSSProperties, useState } from 'react';
import { App, Button } from 'antd';

import CreateTeamSteps from 'components/steps/CreateTeamSteps';
import CreateEditModal from 'components/steps/common/CreateEditModal';
import { isPersonViewer } from 'utils';

const CreateTeamButton = (props: { style?: CSSProperties }) => {
    const { message } = App.useApp();
    const [openModal, setOpenModal] = useState(false);

    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    async function onSuccess() {
        setOpenModal(false);
        message.success('Your team was created successfully.');
    }

    function onClose() {
        setOpenModal(false);
    }

    return (
        <>
            <Button style={props.style} onClick={() => setOpenModal(true)}>
                Create a team
            </Button>
            <CreateEditModal title={'Create a new team'} open={openModal} onCancel={onClose}>
                <CreateTeamSteps onSuccess={onSuccess} onCancel={onClose} />
            </CreateEditModal>
        </>
    );
};

export default CreateTeamButton;
