import { Card, Typography } from 'antd';

import FieldsTable from './table/FieldsTable';

const FieldsCard = () => {
    return (
        <Card>
            <Typography.Title level={2}>Fields</Typography.Title>
            <FieldsTable />
        </Card>
    );
};

export default FieldsCard;
