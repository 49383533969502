import { DataType } from './EditableDnDTable';

function countKeysWithoutKey(a: DataType) {
    let count = 0;
    for (const key in a) {
        if (a.hasOwnProperty(key) && key !== 'key') {
            count++;
        }
    }
    return count;
}

export function equalExceptKey(a: DataType, b: DataType) {
    let count = 0;
    for (const key in a) {
        if (a.hasOwnProperty(key) && key !== 'key') {
            if (a[key] !== b[key]) {
                return false;
            }
            count += 1;
        }
    }
    return count === countKeysWithoutKey(b);
}
