import { Form } from 'antd';
import { SingleSelectField, MultiSelectField } from 'types';
import OptionsTable from './OptionsTable';

const SelectField = (props: {
    field: SingleSelectField | MultiSelectField;
    setField: (field: SingleSelectField | MultiSelectField) => void;
}) => {
    function setOptions(options: string[] | undefined) {
        options = options ? options : [];
        props.setField({ ...props.field, config: { options } });
    }

    return (
        <Form.Item
            className="form-control"
            label="Options"
            name={'config'}
            required
            rules={[
                {
                    validator: () => {
                        if (props.field.config && props.field.config.options.length > 0) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please add some options.'));
                    },
                },
            ]}
        >
            <OptionsTable options={props.field.config?.options} setOptions={setOptions} />
        </Form.Item>
    );
};

export default SelectField;
