import type { CPGNode } from 'components/canvas/cpg/types';

export function fillColorFromStroke(color: string) {
    return color + '80';
}

export function findNodeById(root: CPGNode, id: string): CPGNode | null {
    if (root.obj.id === id) {
        return root;
    }
    if (root.children) {
        for (const node of root.children) {
            const found = findNodeById(node, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

function nodeCount(root: CPGNode) {
    let count = 1;
    if (root.children) {
        for (const node of root.children) {
            count += nodeCount(node);
        }
    }
    return count;
}

/* Show a single zone view */
export function showZone(root: CPGNode, count: number, id: string) {
    const node = findNodeById(root, id);
    if (node) {
        return {
            root: node,
            count: nodeCount(node),
        };
    }
    return { root, count };
}
