import { Select } from 'antd';
import { UserType } from 'types';

const OPTIONS = [
    { value: 'regular', label: 'Contributor (regular)' },
    { value: 'admin', label: 'Organization Admin (admin)' },
    // { value: 'system', label: 'Super Admin (system)' },
    { value: 'read_only', label: 'Viewer (read_only)' },
];

const UserTypeSelect = (props: { userType: UserType; setUserType: (userType: UserType) => void }) => {
    return (
        <Select
            value={props.userType}
            showSearch={true}
            onChange={props.setUserType}
            style={{ width: '100%', maxWidth: '400px' }}
            options={OPTIONS}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
    );
};

export default UserTypeSelect;
