import { useState } from 'react';
import { Card, Space } from 'antd';

import type { Meeting, NonEmptyArray, Team } from 'types';
import { getDefaultTeamMeetingId, setDefaultTeamMeeting } from './utils';
import TeamMeetingCardHeader from './TeamMeetingsCardHeader';
import TeamMeetingDraggableContainer from './TeamMeetingDraggableContainer';

const TeamMeetingsCard = (props: { team: Team; meetings: NonEmptyArray<Meeting> }) => {
    const [meetingId, setMeetingId] = useState(() => {
        const meetingId = getDefaultTeamMeetingId(props.team.id);
        if (meetingId) {
            const meeting = props.meetings.find((m) => m.id === meetingId);
            if (meeting) {
                return meetingId;
            }
        }
        return props.meetings.length ? props.meetings[0].id : undefined;
    });
    const meeting = props.meetings.find((m) => m.id === meetingId);
    if (!meeting) return null;

    const onSetMeeting = (meeting: Meeting) => {
        setMeetingId(meeting.id);
        setDefaultTeamMeeting(props.team.id, meeting.id);
    };

    return (
        <Card>
            <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
                <TeamMeetingCardHeader meeting={meeting} setMeeting={onSetMeeting} meetings={props.meetings} />
                <TeamMeetingDraggableContainer meeting={meeting} />
            </Space>
        </Card>
    );
};

export default TeamMeetingsCard;
