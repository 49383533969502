import showdown from 'showdown';

import { BaseObject, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';
import DrawerDescription from './DrawerDescription';

const TemplateFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any };
    templateField: TemplateField;
}) => {
    const field = getField(props.templateField);

    const value = props.obj[field.name!];
    if (!value) {
        return null;
    }

    const converter = new showdown.Converter();
    const html = converter.makeHtml(value);

    return <DrawerDescription title={fieldLabel(field.name)} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default TemplateFieldDrawerDescription;
