import React, { CSSProperties, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CreateEditModal from './CreateEditModal';

const CreateButton = (props: {
    title: string;
    buttonText?: string;
    style?: CSSProperties;
    children: (onSuccess: () => void, onClose: () => void) => React.ReactNode;
    onSuccess?: () => void;
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);

    async function onSuccess() {
        setOpenModal(false);
        setOpenTooltip(false);
        props.onSuccess?.();
    }

    function onClose() {
        setOpenModal(false);
        setOpenTooltip(false);
    }

    function onClick() {
        setOpenTooltip(false);
        setOpenModal(true);
    }

    return (
        <Tooltip title={props.buttonText ? undefined : props.title} open={openTooltip} onOpenChange={setOpenTooltip}>
            <Button
                style={props.style}
                type={'primary'}
                shape={props.buttonText ? 'default' : 'circle'}
                icon={<PlusOutlined />}
                onClick={onClick}
            >
                {props.buttonText}
            </Button>
            <CreateEditModal title={props.title} open={openModal} onCancel={onClose}>
                {props.children(onSuccess, onClose)}
            </CreateEditModal>
        </Tooltip>
    );
};

export default CreateButton;
