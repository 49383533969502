import { ReactNode, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import type { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { kebabCase } from 'change-case';
import classNames from 'classnames';

import { useHash } from 'hooks/hash';
import { useSubscribe } from 'hooks/pubsub';
import MainSider from 'components/sider/MainSider';
import MainHeader from 'components/header/MainHeader';
import { isString } from 'utils';

import styles from 'components/layout/styles.module.scss';

const MainLayout = (props: {
    className?: string;
    pageTitle: ReactNode | undefined;
    breadcrumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined;
}) => {
    const { hash } = useHash();
    useSubscribe();

    const className = classNames(styles.layout, props.className, {
        [isString(props.pageTitle) ? kebabCase(props.pageTitle as string) : '']:
            !!props.pageTitle && isString(props.pageTitle),
    });

    useEffect(() => {
        if (hash) {
            const anchorElement = document.getElementById(hash);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <Layout className={className}>
            <MainSider />
            <Layout className={styles.main}>
                <MainHeader pageTitle={props.pageTitle} breadcrumbs={props.breadcrumbs} />
                <Layout.Content className={styles.content}>
                    <Outlet />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
