import { Form, Input } from 'antd';
import FormButtonBar from 'components/forms/common/FormButtonBar';
import PersonFormItem from 'components/forms/common/PersonFormItem';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Person } from 'types';
import { isPersonAdmin } from 'utils';

const PersonForm = (props: {
    person?: Person;
    onFinish?: (person: Person) => void;
    onCancel?: () => void;
    loading?: boolean;
}) => {
    const isAdmin = isPersonAdmin(useCurrentUser());
    const omitIds = props.person ? [props.person.id] : undefined;

    return (
        <Form name={'person'} initialValues={props.person} onFinish={props.onFinish}>
            <Form.Item<Person>
                className={'form-control'}
                label={'First Name'}
                name={'first_name'}
                rules={[{ required: true, message: `Please enter the person's first name.` }]}
            >
                <Input />
            </Form.Item>
            <Form.Item<Person>
                className={'form-control'}
                label={'Last Name'}
                name={'last_name'}
                rules={[{ required: true, message: `Please enter the person's last name.` }]}
            >
                <Input />
            </Form.Item>

            {isAdmin && (
                <>
                    <Form.Item<Person> className={'form-control'} label={'Title'} name={'title'}>
                        <Input />
                    </Form.Item>
                    <PersonFormItem name={'reports_to_id'} label={'Reports To'} omitIds={omitIds} />
                </>
            )}
            <FormButtonBar onCancel={props.onCancel} loading={props.loading} />
        </Form>
    );
};

export default PersonForm;
