import { Typography, Flex } from 'antd';
import PersonAvatar from 'components/avatar/PersonAvatar';
import CardLink from 'components/cards/CardLink';

import styles from 'pages/teams/cards/styles.module.scss';

import type { Role } from 'types';
import { usePeople } from 'hooks/usePeople';
import { isDefined, map } from 'utils';

const RoleCard = (props: { role: Role; onClick?: () => void; showAvatar?: boolean }) => {
    const people = map(usePeople());
    const assignee = props.role.assignment_id ? people[props.role.assignment_id] : undefined;
    const showAvatar = isDefined(props.showAvatar) ? props.showAvatar : true;

    return (
        <CardLink className={styles.card} onClick={props.onClick}>
            <Flex>
                {showAvatar ? <PersonAvatar person={assignee} /> : null}
                <div className="card-info">
                    {/*
                        <span className='designation'>Team Lead</span>
                        */}
                    <Typography.Title level={3}>{props.role.name}</Typography.Title>
                    <div className="status">
                        <i></i>
                        <span>Active</span>
                        {/*
                            <label className='checkbox'>
                                <CheckOutlined />
                            </label>
                            */}
                    </div>
                </div>
            </Flex>
            {/*
                <div className='link-icon'>
                    <MoreOutlined />
                </div>
                */}
        </CardLink>
    );
};

export default RoleCard;
