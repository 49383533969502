import { Empty, Flex } from 'antd';

const CardEmpty = () => {
    return (
        <Flex style={{ width: '100%', padding: '1em' }} justify={'center'}>
            <Empty />
        </Flex>
    );
};

export default CardEmpty;
