import { PropsWithChildren } from 'react';
import { Typography } from 'antd';

import styles from './styles.module.scss';

const SettingsSectionTitle = (props: PropsWithChildren) => {
    return (
        <Typography.Title className={styles.sectionTitle} level={4}>
            {props.children}
        </Typography.Title>
    );
};

export default SettingsSectionTitle;
