import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { MeetingType, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type MeetingTypesState = {
    entities: MeetingType[];
    status: SliceStatus;
    error: string;
};

export const fetchMeetingTypes = createAsyncThunk<MeetingType[]>('meeting_types/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/meeting_types`);
});

export const deleteMeetingType = createAsyncThunk<
    MeetingType,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('MeetingType/delete', async (MeetingTypeId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<MeetingType>(`/org/${state.org.value.id}/meeting_types/${MeetingTypeId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createMeetingType = createAsyncThunk<
    MeetingType,
    MeetingType,
    {
        rejectValue: ErrorDetail;
    }
>('risk/create', async (MeetingType, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/meeting_types`, MeetingType);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateMeetingType = Partial<MeetingType> & { id: string };
export const updateMeetingType = createAsyncThunk<
    MeetingType,
    UpdateMeetingType,
    {
        rejectValue: ErrorDetail;
    }
>('risk/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/meeting_types/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as MeetingTypesState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const MeetingTypeSlice = createSlice({
    name: 'meeting_types',
    initialState: INITIAL_STATE satisfies MeetingTypesState as MeetingTypesState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMeetingTypes.pending, (state: MeetingTypesState) => {
                state.status = 'pending';
            })
            .addCase(fetchMeetingTypes.fulfilled, (state: MeetingTypesState, action: PayloadAction<MeetingType[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as MeetingType[];
            })
            .addCase(fetchMeetingTypes.rejected, (state: MeetingTypesState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(
                createMeetingType.fulfilled,
                (state: MeetingTypesState, action: PayloadAction<MeetingType | ErrorDetail>) => {
                    state.entities.push(action.payload as MeetingType);
                },
            )
            .addCase(deleteMeetingType.fulfilled, (state: MeetingTypesState, action) => {
                state.entities = state.entities.filter(
                    (MeetingType: MeetingType) => MeetingType.id !== action.payload.id,
                );
            })
            .addCase(CLEAR_ORG_ACTION, (state: MeetingTypesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: MeetingTypesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(
                updateMeetingType.fulfilled,
                (state: MeetingTypesState, action: PayloadAction<MeetingType | ErrorDetail>) => {
                    const risk = action.payload as MeetingType;
                    const index = state.entities.findIndex((r) => r.id === risk.id);
                    state.entities[index] = risk;
                },
            );
    },
});

export const selectMeetingTypes = (state: RootState) => state.meetingTypes.entities;
export const selectMeetingTypeStatus = (state: RootState) => state.meetingTypes.status;

export default MeetingTypeSlice.reducer;
