import { useUpdateDispatch } from 'components/forms/hooks';
import React, { useState } from 'react';
import { App, Button, Form, FormProps, Input } from 'antd';

import { type ErrorDetail, type ErrorDetailDispatch, type TemplatedObject } from 'types';
import { useTemplate } from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';
import { fieldLabel, getField } from 'utils';

type DataType = TemplatedObject & { [p: string]: any };

const ObjectForm = (props: {
    obj: TemplatedObject;
    onSave?: (obj: TemplatedObject) => void;
    onCancel?: () => void;
}) => {
    const [obj, setObject] = useState<DataType>(props.obj);

    const template = useTemplate(props.obj?.template_id, props.obj?.type);
    const templateFields = template ? template.template_fields : [];

    const updateDispatch = useUpdateDispatch();
    const { message } = App.useApp();

    const onFinish: FormProps<DataType>['onFinish'] = async () => {
        const res = await updateDispatch(obj);
        if ((res as ErrorDetailDispatch<TemplatedObject>).error) {
            message.error((res.payload as ErrorDetail).detail.toString());
            return;
        }
        props.onSave?.(obj);
        message.success(`${fieldLabel(obj.type)} updated successfully.`);
    };

    return (
        <Form layout={'vertical'} onFinish={onFinish}>
            <Form.Item<TemplatedObject>
                label="Name"
                name={'name'}
                rules={[{ required: true, message: 'Please enter a value.' }]}
                initialValue={props.obj.name}
            >
                <Input
                    onChange={(event) => {
                        setObject({ ...obj, name: event.target.value });
                    }}
                />
            </Form.Item>

            {templateFields.map((templateField, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    templateField={templateField}
                    obj={obj}
                    onChange={(value) => {
                        const newObj = { ...obj, [getField(templateField).name]: value };
                        setObject(newObj);
                    }}
                />
            ))}

            <Form.Item style={{ marginTop: '36px' }}>
                <Button type="primary" htmlType={'submit'}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ObjectForm;
