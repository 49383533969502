import React, { PropsWithChildren } from 'react';

import { Button, ButtonProps } from 'antd';

import styles from './styles.module.scss';

const SettingsCreateButton = (
    props: PropsWithChildren<
        {
            onClick?: () => void;
        } & Pick<ButtonProps, 'size' | 'type'>
    >,
) => {
    const size = props.size ? props.size : 'small';
    const type = props.type ? props.type : 'primary';
    return (
        <Button className={styles.createButton} type={type} onClick={props.onClick} size={size}>
            {props.children}
        </Button>
    );
};

export default SettingsCreateButton;
