import { useRef } from 'react';

export function useLatestPromise<T>() {
    const currentPromiseRef = useRef(0);

    const execute = async (promiseFunc: () => Promise<T>) => {
        const currentId = ++currentPromiseRef.current;

        try {
            const result = await promiseFunc();
            if (currentId === currentPromiseRef.current) {
                return result; // Only return if this is the latest promise.
            }
        } catch (error) {
            if (currentId === currentPromiseRef.current) {
                throw error; // Rethrow the error if it's the latest promise.
            }
        }

        return null; // Ignore results from older promises.
    };

    return { execute };
}
