import { Flex, Typography } from 'antd';
import { useCurrentUser } from 'hooks/useCurrentUser';
import UserImage from './UserImage';
import { forwardRef } from 'react';

const HeaderUser = forwardRef<HTMLElement>((props, ref) => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return null;
    }

    return (
        <Flex {...props} ref={ref} align={'center'}>
            <UserImage user={currentUser} />
            <Typography.Text className="h-username" style={{ marginLeft: '4px' }}>
                {currentUser.name}
            </Typography.Text>
        </Flex>
    );
});

export default HeaderUser;
