import { useCurrentUser } from 'hooks/useCurrentUser';
import { Team } from 'types';
import CreateObjectiveSteps from 'components/steps/CreateObjectiveSteps';
import CreateButton from 'components/steps/common/CreateButton';
import { isPersonViewer } from 'utils';

const AddObjectiveButton = (props: { team: Team }) => {
    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    return (
        <CreateButton title={'Add Objective'}>
            {(onSuccess, onCancel) => (
                <CreateObjectiveSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </CreateButton>
    );
};

export default AddObjectiveButton;
