import { ReactNode } from 'react';
import { Button, Space, Form, Flex } from 'antd';

const FormButtonBar = (props: { loading?: boolean; onCancel?: () => void; extra?: ReactNode }) => {
    return (
        <Form.Item>
            <Flex justify={'space-between'}>
                <Space>
                    <Button type="primary" htmlType="submit" loading={!!props.loading}>
                        Submit
                    </Button>
                    <Button
                        type="default"
                        style={{ marginRight: '8px' }}
                        onClick={props.onCancel}
                        disabled={!!props.loading}
                    >
                        Cancel
                    </Button>
                </Space>
                {props.extra ? props.extra : null}
            </Flex>
        </Form.Item>
    );
};

export default FormButtonBar;
