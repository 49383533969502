import { CreateEditTemplateCustomField } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';

import TemplateFieldForm from './TemplateFieldForm';

const CreateEditTemplateFieldModal = (props: {
    templateField?: CreateEditTemplateCustomField;
    open: boolean;
    onSave: (templateField: CreateEditTemplateCustomField) => void;
    onCancel?: () => void;
    excludedFieldIds?: string[];
}) => {
    const title = props.templateField ? 'Edit Template Field' : 'Add Template Field';

    return (
        <CreateEditModal title={title} open={props.open} onCancel={() => props.onCancel?.()} width={500}>
            <TemplateFieldForm
                templateField={props.templateField}
                onFinish={props.onSave}
                onCancel={() => props.onCancel?.()}
                excludedFieldIds={props.excludedFieldIds}
            />
        </CreateEditModal>
    );
};

export default CreateEditTemplateFieldModal;
