import { useNavigate } from 'react-router-dom';
import { App, Button, Flex, Modal, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Objective } from 'types';

import { getDispatchError, hasDispatchError, isDefined, isPersonAdmin, isUndefined } from 'utils';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteObjective } from 'features/objectiveSlice';

const DeleteObjectiveButton = (props: {
    objectiveId: string;
    onDelete?: (objective: Objective) => void;
    navigate?: string | boolean;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const {
        token: { colorError },
    } = theme.useToken();
    const navigate = useNavigate();

    const isAdmin = isPersonAdmin(useCurrentUser());
    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deleteObjective(props.objectiveId));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        props.onDelete?.(res.payload as Objective);
        if (isDefined(props.navigate) && props.navigate) {
            navigate(props.navigate as string, { replace: true });
        } else if (isUndefined(props.navigate)) {
            navigate('/objectives', { replace: true });
        }
        message.success('Your objective was successfully deleted.');
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: 'Confirm Objective Deletion',
                        content:
                            'Are you sure you want to permanently delete this objective?  This operation cannot be undone.',
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <CancelBtn />
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Objective',
                        okButtonProps: { type: 'default', danger: true },
                        icon: <CloseCircleOutlined style={{ color: colorError }} />,
                        onOk: onConfirm,
                    });
                }}
            >
                Delete Objective
            </Button>
        </Flex>
    );
};

export default DeleteObjectiveButton;
