import type { Role, TemplateNativeField } from 'types';

import RolePersonDetail from 'components/drawers/handlers/role/RolePersonDetail';
import RoleAccountableFor from 'components/drawers/handlers/role/RoleAccountableFor';

const handleRole = (role: Role, templateField: TemplateNativeField) => {
    switch (templateField.native_field.name) {
        case 'assignment_id':
            return <RolePersonDetail role={role} />;
        case 'accountable_for':
            return <RoleAccountableFor role={role} />;
    }
    return undefined;
};

export default handleRole;
