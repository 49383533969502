import { Typography } from 'antd';
import CardLink from 'components/cards/CardLink';
import { Decision } from 'types';

import styles from './styles.module.scss';

const DecisionCard = (props: { decision: Decision; onClick?: () => void }) => {
    return (
        <CardLink className={styles.card} onClick={props.onClick}>
            <Typography.Title level={3}>{props.decision.name}</Typography.Title>
        </CardLink>
    );
};

export default DecisionCard;
