import { Typography } from 'antd';
import { useZone } from 'hooks/useZones';
import { useParams } from 'react-router-dom';

const ZoneChartTitle = () => {
    const { zoneId } = useParams();
    const zone = useZone(zoneId);

    return <Typography.Title>[Zone] {zone?.name}</Typography.Title>;
};

export default ZoneChartTitle;
