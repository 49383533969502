import { useEffect } from 'react';

import { Person, Role, Team } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchRoles, selectRoles, selectRoleStatus } from 'features/roleSlice';
import { nameSort } from 'utils';

export function useRoles(): Role[] {
    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectRoles);
    const roleStatus = useAppSelector(selectRoleStatus);

    useEffect(() => {
        if (roleStatus === 'idle') {
            dispatch(fetchRoles());
        }
    }, [roleStatus, dispatch]);

    return [...roles].sort(nameSort);
}

export function useRole(roleId: string | undefined) {
    const roles = useRoles();
    return roles.find((role) => role.id === roleId);
}

export function usePersonRoles(person: Person | null | undefined): Role[] {
    const roles = useRoles();
    return person ? roles.filter((role) => role.assignment_id === person.id) : [];
}

export function useTeamRoles(team: Team | null | undefined): Role[] {
    const roles = useRoles();
    return team ? roles.filter((role) => role.team.id === team.id) : [];
}
