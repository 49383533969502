import type { FDGLink, FDGNode } from './types';
import { defaultRadius } from './utils';
import { generateColor } from 'utils';

export function nodeMap(nodes: FDGNode[]) {
    const map = {} as Record<string, FDGNode>;
    for (const node of nodes) {
        map[node.id] = node;
    }
    return map;
}

function drawLink(context: CanvasRenderingContext2D, d: FDGLink, map: Record<string, FDGNode>) {
    const source = map[(d.source as FDGNode).id];
    context.moveTo(source.x!, source.y!);

    const target = map[(d.target as FDGNode).id];
    context.lineTo(target.x!, target.y!);
}

function drawNode(
    context: CanvasRenderingContext2D,
    shadowContext: CanvasRenderingContext2D,
    node: FDGNode,
    defaultRadius: number,
    uniqueColor: string,
) {
    const radius = node.radius ? node.radius : defaultRadius;

    context.beginPath();
    context.moveTo(node.x! + radius, node.y!);
    context.arc(node.x!, node.y!, radius, 0, 2 * Math.PI);
    context.fillStyle = node.fillStyle ? node.fillStyle : '#fff';
    context.strokeStyle = node.strokeStyle ? node.strokeStyle : '#000';
    context.lineWidth = 4;
    context.fill();
    context.stroke();

    shadowContext.beginPath();
    shadowContext.moveTo(node.x! + radius, node.y!);
    shadowContext.arc(node.x!, node.y!, radius, 0, 2 * Math.PI);
    shadowContext.strokeStyle = uniqueColor;
    shadowContext.fillStyle = uniqueColor;
    shadowContext.fill();
    shadowContext.stroke();

    node.radius = radius;
}

function draw(props: {
    context: CanvasRenderingContext2D;
    shadowContext: CanvasRenderingContext2D;
    nodes: FDGNode[];
    links: FDGLink[];
    width: number;
    height: number;
    radius?: number;
}) {
    const map = nodeMap(props.nodes);
    const colorMap = new Map<string, FDGNode>();

    const context = props.context;
    const shadowContext = props.shadowContext;

    const radius = defaultRadius(props.width, props.nodes.length, props.radius);

    context.clearRect(0, 0, props.width, props.height);
    shadowContext.clearRect(0, 0, props.width, props.height);

    context.save();
    context.globalAlpha = 0.6;
    context.strokeStyle = '#999';

    context.lineWidth = 4;
    context.beginPath();
    props.links.forEach((link) => drawLink(context, link, map));
    context.stroke();
    context.restore();

    context.save();
    context.globalAlpha = 1;
    props.nodes.forEach((node, index) => {
        const uniqueColor = generateColor(index, props.nodes.length);
        colorMap.set(uniqueColor, node);

        drawNode(context, shadowContext, node, radius, uniqueColor);
    });
    context.restore();
    return colorMap;
}

export default draw;
