import type { Organization, Person, Role, Team, Zone } from 'types';
import type { CPGNode } from 'components/canvas/cpg/types';
import styles from './styles.module.scss';

function data(props: {
    org: Organization;
    zones: Zone[];
    zoneFilters: string[];
    teams: Team[];
    roles: Role[];
    people: Record<string, Person>;
}): { root: CPGNode; count: number } {
    const graph: Record<string, CPGNode> = {};

    for (const zone of props.zones) {
        graph[zone.id] = {
            id: zone.id,
            title: zone.name,
            obj: zone,
            children: [],
            parentId: null,
            value: 2,
            strokeStyle: styles.zoneColorStroke,
            fillStyle: styles.zoneColorFill,
        };
    }

    for (const zone of props.zones) {
        if (zone.zone?.id) {
            graph[zone.zone?.id].children.push(graph[zone.id]);
            graph[zone.id].parentId = zone.zone?.id || null;
        }
    }

    for (const team of props.teams) {
        graph[team.id] = {
            id: team.id,
            title: team.name,
            obj: team,
            children: [],
            parentId: null,
            value: 2,
            strokeStyle: styles.teamColorStroke,
            fillStyle: styles.teamColorFill,
        };
    }

    for (const team of props.teams) {
        if (team.zone?.id) {
            graph[team.zone?.id].children.push(graph[team.id]);
            graph[team.id].parentId = team.zone?.id || null;
        }
    }

    for (const role of props.roles) {
        const user = role.assignment_id ? props.people[role.assignment_id] : undefined;
        graph[role.team.id].children.push({
            id: role.id,
            title: role.name,
            obj: role,
            value: 1,
            children: [],
            parentId: role.team.id,
            strokeStyle: styles.roleColorStroke,
            fillStyle: role.assignment_id ? styles.roleColorFill : 'transparent',
            src: user?.picture_large,
            notFocusable: true,
        });
    }

    const root = {
        obj: props.org,
        children: [] as CPGNode[],
        parentId: null,
    } as CPGNode;

    for (const zone of props.zones) {
        if (graph[zone.id].parentId === null && !props.zoneFilters.includes(zone.id)) {
            graph[zone.id].parentId = props.org.id;
            root.children.push(graph[zone.id]);
        }
    }
    return { root, count: Object.keys(graph).length + props.roles.length };
}

export default data;
