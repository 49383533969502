import { Space } from 'antd';
import DeleteDecisionButton from 'components/buttons/DeleteDecisionButton';
import DeleteObjectiveButton from 'components/buttons/DeleteObjectiveButton';
import DeleteRoleButton from 'components/buttons/DeleteRoleButton';
import DeleteTeamButton from 'components/buttons/DeleteTeamButton';
import DeleteZoneButton from 'components/buttons/DeleteZoneButton';
import DangerZoneCard from 'components/cards/DangerZoneCard';
import TeamMeetingsTable from 'components/drawers/team/meetingTable/TeamMeetingsTable';
import TeamProvider from 'providers/TeamProvider';
import type { Team, TemplatedObject } from 'types';

import ObjectDrawerDisplay from './ObjectDrawerDisplay';
import ObjectDrawerEdit from './ObjectDrawerEdit';

const ObjectDrawerContents = (props: {
    obj: TemplatedObject;
    edit?: boolean;
    setEdit?: (value: boolean) => void;
    onClose?: () => void;
}) => {
    if (props.edit) {
        return (
            <>
                <ObjectDrawerEdit
                    obj={props.obj}
                    onFinish={() => props.setEdit?.(false)}
                    onCancel={() => props.setEdit?.(false)}
                />
                {props.obj.type === 'decision' && (
                    <DangerZoneCard>
                        <DeleteDecisionButton
                            decisionId={props.obj.id}
                            navigate={false}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerZoneCard>
                )}
                {props.obj.type === 'objective' && (
                    <DangerZoneCard>
                        <DeleteObjectiveButton
                            objectiveId={props.obj.id}
                            navigate={false}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerZoneCard>
                )}
                {props.obj.type === 'role' && (
                    <DangerZoneCard>
                        <DeleteRoleButton
                            roleId={props.obj.id}
                            navigate={false}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerZoneCard>
                )}
                {props.obj.type === 'team' && (
                    <TeamProvider team={props.obj as Team}>
                        <Space className={'w-100'} direction={'vertical'} size={'large'}>
                            <TeamMeetingsTable team={props.obj as Team} />
                            <DangerZoneCard>
                                <DeleteTeamButton teamId={props.obj.id} />
                            </DangerZoneCard>
                        </Space>
                    </TeamProvider>
                )}
                {props.obj.type === 'zone' && (
                    <DangerZoneCard>
                        <DeleteZoneButton
                            zoneId={props.obj.id}
                            onDelete={() => {
                                props.setEdit?.(false);
                                props.onClose?.();
                            }}
                        />
                    </DangerZoneCard>
                )}
            </>
        );
    }

    return <ObjectDrawerDisplay obj={props.obj as TemplatedObject} />;
};

export default ObjectDrawerContents;
