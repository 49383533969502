import { useCurrentUser } from 'hooks/useCurrentUser';
import React from 'react';

import CreateRoleSteps from 'components/steps/CreateRoleSteps';
import CreateButton from 'components/steps/common/CreateButton';
import { isPersonViewer } from 'utils';

const CreateRoleButton = () => {
    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    return (
        <CreateButton title={'Create Role'} buttonText={'Create Role'}>
            {(onSuccess, onCancel) => <CreateRoleSteps onSuccess={onSuccess} onCancel={onCancel} />}
        </CreateButton>
    );
};

export default CreateRoleButton;
