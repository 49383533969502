import { FieldWithRequired } from 'types';

const fields: FieldWithRequired[] = [
    { id: '67994e8837654b5b', name: 'team', type: 'one-to-one', config: { allowed: ['team'] }, required: true },
    { id: '67990c0b371053b5', name: 'accountabilities', type: 'text', config: {}, required: true },
    { id: '67990c1e37cf1dc4', name: 'accountable_to', type: 'one-to-one', config: { allowed: ['role'] } },
    { id: '67990c4a3747e2f6', name: 'accountable_for', type: 'one-to-many', config: { allowed: ['role'] } },
    { id: '67990c503768621b', name: 'assignment_id', type: 'single-select', config: { options: [] } },
];

export default fields;
