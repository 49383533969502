import { useEffect, useState } from 'react';

import { Form, Input } from 'antd';
import {
    CreateEditTemplate,
    CreateEditTemplateCustomField,
    CreateEditTemplateField,
    CreateEditTemplateNativeField,
    Field,
    Template,
    TemplateCustomField,
    TemplateNativeField,
} from 'types';

import styles from './styles.module.scss';
import { isCustomTemplateField } from 'utils';
import SettingsButtonBar from 'pages/settings/_common/SettingsButtonBar';

import ObjectTypeSelect from 'components/forms/common/ObjectTypeSelect';
import TemplateFieldsTable from './TemplateFieldsTable';
import DeleteTemplateButton from './DeleteTemplateButton';
import base from '../base';

type FormType = Omit<CreateEditTemplate, 'template_fields'>;

const TemplateForm = (props: {
    template?: Template;
    onFinish: (template: CreateEditTemplate) => void;
    onCancel?: () => void;
}) => {
    const [form] = Form.useForm<FormType>();

    const [savedType, setSavedType] = useState(props.template?.type);
    const type = Form.useWatch('type', form);

    useEffect(() => {
        if (type && savedType !== type) {
            const fields = base[type];
            if (fields) {
                const templateFields: CreateEditTemplateNativeField[] = fields.map((field, index) => {
                    return {
                        native_field: field as Field,
                        required: !!field.required,
                        order_by: index,
                    };
                });
                setTemplate((template) => {
                    return { ...template, template_fields: templateFields };
                });
            } else {
                setTemplate((template) => {
                    return { ...template, template_fields: [] };
                });
            }
            setSavedType(type);
        }
    }, [type, savedType]);

    const [template, setTemplate] = useState<CreateEditTemplate>(() => {
        if (props.template) {
            return {
                id: props.template.id,
                name: props.template.name,
                description: props.template.description,
                template_fields: props.template.template_fields.map((templateField) => {
                    if (isCustomTemplateField(templateField)) {
                        const customTemplateField = templateField as TemplateCustomField;
                        return {
                            field_id: customTemplateField.custom_field.id,
                            required: customTemplateField.required,
                            order_by: customTemplateField.order_by,
                        } as CreateEditTemplateCustomField;
                    } else {
                        const nativeTemplateField = templateField as TemplateNativeField;
                        return {
                            required: nativeTemplateField.required,
                            order_by: nativeTemplateField.order_by,
                            native_field: nativeTemplateField.native_field,
                        } as CreateEditTemplateNativeField;
                    }
                }),
            } as CreateEditTemplate;
        }
        return {} as CreateEditTemplate;
    });

    function onFinish(values: FormType) {
        const newTemplate = { ...template, ...values } as CreateEditTemplate;
        props.onFinish(newTemplate);
    }

    async function setTemplateFields(templateFields: CreateEditTemplateField[]) {
        const newTemplate = { ...template, template_fields: templateFields };
        setTemplate(newTemplate);
        try {
            await form.validateFields();
        } catch (e) {}
    }

    return (
        <Form<FormType>
            form={form}
            name="field"
            className={styles.form}
            initialValues={props.template}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                label="Name"
                name="name"
                className="form-control"
                rules={[{ required: true, message: 'Please input a name.' }]}
            >
                <Input value={template.name} />
            </Form.Item>

            <Form.Item<Template> label="Description" name="description" className="form-control">
                <Input.TextArea />
            </Form.Item>

            <Form.Item<Template>
                label="Object"
                name="type"
                className="form-control"
                rules={[{ required: true, message: 'Select a template type.' }]}
                help={type ? 'Note: Changing the object will reset the fields list below.' : undefined}
            >
                <ObjectTypeSelect />
            </Form.Item>

            {!!type && (
                <Form.Item<Template>
                    className="form-control"
                    label="Fields"
                    required
                    rules={[
                        {
                            validator: () => {
                                if (template?.template_fields && template.template_fields.length > 0) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Please add some fields.'));
                            },
                        },
                    ]}
                >
                    <TemplateFieldsTable
                        templateFields={template.template_fields || []}
                        setTemplateFields={setTemplateFields}
                    />
                </Form.Item>
            )}

            <SettingsButtonBar
                extraButtons={props.template ? <DeleteTemplateButton templateId={props.template.id} /> : undefined}
                onCancel={() => props.onCancel?.()}
            />
        </Form>
    );
};

export default TemplateForm;
