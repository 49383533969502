import type { CSSProperties } from 'react';
import styles from './styles.module.scss';
import { useThemeMode } from 'hooks/useThemeMode';
import { fillColorFromStroke } from '../utils';

const ZoneChartLegend = (props: { style?: CSSProperties }) => {
    const mode = useThemeMode();
    return (
        <div className={styles.legend} style={props.style}>
            <div>
                <span
                    className={styles.dot}
                    style={{
                        backgroundColor: styles.zoneColorFill,
                        borderColor: styles.zoneColorStroke,
                    }}
                />
                <span>Zone</span>
            </div>
            <div>
                <span
                    className={styles.dot}
                    style={{
                        backgroundColor: fillColorFromStroke(styles.teamColorStroke),
                        borderColor: styles.teamColorStroke,
                    }}
                />
                <span>Team</span>
            </div>
            <div>
                <span
                    className={styles.dot}
                    style={{
                        backgroundColor: fillColorFromStroke(styles.roleColorStroke),
                        borderColor: styles.roleColorStroke,
                    }}
                />
                <span>Role (filled)</span>
            </div>
            <div>
                <span
                    className={styles.dot}
                    style={{
                        backgroundColor: mode === 'dark' ? styles.darkBg : styles.lightBg,
                        borderColor: styles.roleColorStroke,
                    }}
                />
                <span>Role (unfilled)</span>
            </div>
        </div>
    );
};

export default ZoneChartLegend;
