import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Decision, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type DecisionsState = {
    entities: Decision[];
    status: SliceStatus;
    error: string;
};

export const fetchDecisions = createAsyncThunk<Decision[]>('decisions/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/decisions`);
});

export const deleteDecision = createAsyncThunk<
    Decision,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('decision/delete', async (decisionId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Decision>(`/org/${state.org.value.id}/decisions/${decisionId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createDecision = createAsyncThunk<
    Decision,
    Decision,
    {
        rejectValue: ErrorDetail;
    }
>('decision/create', async (decision, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/decisions`, decision);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateDecision = Partial<Decision> & { id: string };
export const updateDecision = createAsyncThunk<
    Decision,
    UpdateDecision,
    {
        rejectValue: ErrorDetail;
    }
>('decision/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/decisions/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as DecisionsState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const decisionSlice = createSlice({
    name: 'decisions',
    initialState: INITIAL_STATE satisfies DecisionsState as DecisionsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDecisions.pending, (state: DecisionsState) => {
                state.status = 'pending';
            })
            .addCase(fetchDecisions.fulfilled, (state: DecisionsState, action: PayloadAction<Decision[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Decision[];
            })
            .addCase(fetchDecisions.rejected, (state: DecisionsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(
                createDecision.fulfilled,
                (state: DecisionsState, action: PayloadAction<Decision | ErrorDetail>) => {
                    state.entities.push(action.payload as Decision);
                },
            )
            .addCase(deleteDecision.fulfilled, (state: DecisionsState, action) => {
                state.entities = state.entities.filter((decision: Decision) => decision.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: DecisionsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: DecisionsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(
                updateDecision.fulfilled,
                (state: DecisionsState, action: PayloadAction<Decision | ErrorDetail>) => {
                    const decision = action.payload as Decision;
                    const index = state.entities.findIndex((r) => r.id === decision.id);
                    state.entities[index] = decision;
                },
            );
    },
});

export const selectDecisions = (state: RootState) => state.decisions.entities;
export const selectDecisionStatus = (state: RootState) => state.decisions.status;

export default decisionSlice.reducer;
