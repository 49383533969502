import { useParams } from 'react-router-dom';
import { useMeetingType } from 'hooks/useMeetingTypes';
import CreateEditMeetingTypePage from './CreateEditMeetingTypePage';

const EditMeetingTypePage = () => {
    const { meetingTypeId } = useParams();
    const meetingType = useMeetingType(meetingTypeId);

    return <CreateEditMeetingTypePage meetingType={meetingType} />;
};

export default EditMeetingTypePage;
