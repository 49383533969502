import { Typography } from 'antd';
import React, { ForwardedRef, forwardRef, type ReactNode } from 'react';

type LinkProps = React.ComponentProps<typeof Typography.Link>;

const SettingsAction = forwardRef(
    (
        props: Pick<LinkProps, 'type' | 'onClick'> & {
            children: ReactNode;
        },
        ref: ForwardedRef<HTMLElement>,
    ) => {
        /* Don't use a style module here, className is changed in PopConfirm */
        return (
            <Typography.Link ref={ref} {...props}>
                {props.children}
            </Typography.Link>
        );
    },
);

export default SettingsAction;
