import { useAppSelector } from 'store';
import { selectTeamStatus } from 'features/teamSlice';
import { selectZoneStatus } from 'features/zoneSlice';
import { selectRoleStatus } from 'features/roleSlice';
import { selectPeopleStatus } from 'features/personSlice';

import type { Zone, Team, Role, Organization, Person } from 'types';
import { map } from 'utils';

import { useZones } from './useZones';
import { useTeams } from './teams';
import { useRoles, useTeamRoles } from './roles';
import { usePeople } from './usePeople';
import { useOrg } from './useOrg';

export type ZoneChartData = {
    org: Organization;
    zones: Zone[];
    teams: Team[];
    roles: Role[];
    people: Person[];
};

export function useZoneChartData(): ZoneChartData | null {
    const zoneStatus = useAppSelector(selectZoneStatus);
    const teamStatus = useAppSelector(selectTeamStatus);
    const roleStatus = useAppSelector(selectRoleStatus);
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const org = useOrg();
    const zones = useZones();
    const teams = useTeams();
    const roles = useRoles();
    const people = usePeople();

    if (
        zoneStatus !== 'succeeded' ||
        teamStatus !== 'succeeded' ||
        roleStatus !== 'succeeded' ||
        peopleStatus !== 'succeeded' ||
        !org
    ) {
        return null;
    }

    return { org, zones, teams, roles, people: people ? people : [] };
}

export function useTeamGraphData(team: Team): { roles: Role[]; people: Person[] } | null {
    const roleStatus = useAppSelector(selectRoleStatus);
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const roles = useTeamRoles(team);
    const people = map(usePeople());
    const peopleWithRoles: Record<string, Person> = {};

    if (roleStatus !== 'succeeded' || peopleStatus !== 'succeeded') {
        return null;
    }

    for (const role of roles) {
        if (role.assignment_id) {
            peopleWithRoles[role.assignment_id] = people[role.assignment_id];
        }
    }

    return { roles, people: Object.keys(peopleWithRoles).map((key) => peopleWithRoles[key]) };
}
