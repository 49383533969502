import { Typography, Flex } from 'antd';
import CardLink from 'components/cards/CardLink';

import styles from 'pages/teams/cards/styles.module.scss';

import type { Team } from 'types';

const TeamCard = (props: { team: Team }) => {
    return (
        <CardLink className={styles.card} href={`/teams/${props.team.id}`}>
            <Flex>
                <div className="card-info">
                    <Typography.Title level={3}>{props.team.name}</Typography.Title>
                    <div className="status">
                        <i></i>
                        <span>Active</span>
                    </div>
                </div>
            </Flex>
        </CardLink>
    );
};

export default TeamCard;
