import { Card, Space } from 'antd';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import type { Meeting, MeetingColumn } from 'types';
import { useThemeMode } from 'hooks/useThemeMode';

import styles from './styles.module.scss';
import AgendaItemCard from './AgendaItemCard';
import AddAgendaItemButton from './AddAgendaItemButton';

const TeamMeetingColumn = (props: { id: string; title: string; meeting: Meeting; column: MeetingColumn }) => {
    const themeMode = useThemeMode();
    const { isOver, setNodeRef } = useDroppable({ id: props.id });

    const style = {
        border: isOver ? '1px dotted gray' : undefined,
    };

    return (
        <SortableContext id={props.id} items={props.column.items}>
            <Card
                ref={setNodeRef}
                style={style}
                className={`team-meeting-column ${themeMode === 'dark' ? styles.columnDark : styles.column}`}
                title={props.title}
                bordered={themeMode === 'dark'}
                extra={<AddAgendaItemButton meeting={props.meeting} column={props.column} />}
            >
                <Space direction={'vertical'} style={{ width: '100%', display: 'flex' }}>
                    {props.column.items.map((agendaItem) => (
                        <AgendaItemCard
                            key={agendaItem.id}
                            meeting={props.meeting}
                            meetingColumn={props.column}
                            agendaItem={agendaItem}
                        />
                    ))}
                </Space>
            </Card>
        </SortableContext>
    );
};

export default TeamMeetingColumn;
