import apiClient from 'api';
import { setCurrentUser } from 'features/currentUserSlice';
import { updatePerson } from 'features/personSlice';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { App, Button, Modal } from 'antd';

import PersonForm from 'components/forms/PersonForm';
import { type AppDispatch, useAppDispatch } from 'store';
import type { ErrorDetail, ErrorDetailDispatch, Person } from 'types';
import { getErrorMessage, isPersonAdmin } from 'utils';

const EditPersonButton = (props: { person: Person; onFinish?: (person: Person) => void }) => {
    const { message } = App.useApp();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    if (props.person.id !== currentUser.id && !isPersonAdmin(currentUser)) {
        return null;
    }

    const onCancel = () => setOpen(false);

    const onUpdateMe = async (person: Person) => {
        try {
            const res = await apiClient.patch<Person>('/auth/me', person);
            /* The /auth/me endpoint isn't org specific so keep user_type and reports_to_id */
            dispatch(
                setCurrentUser({
                    ...currentUser,
                    ...res,
                    user_type: currentUser.user_type,
                    reports_to_id: currentUser.reports_to_id,
                }),
            );
            return true;
        } catch (e) {
            message.error(getErrorMessage(e));
        }
        return false;
    };

    const onUpdatePerson = async (person: Person) => {
        const res = await dispatch(updatePerson({ ...person, id: props.person.id }));
        if ((res as ErrorDetailDispatch<Person>).error) {
            message.error((res.payload as ErrorDetail).detail.toString());
            return false;
        }
        if (props.person.id === currentUser.id) {
            dispatch(setCurrentUser({ ...currentUser, ...(res.payload as Person) }));
        }
        return true;
    };

    const onFinish = async (person: Person) => {
        setLoading(true);
        person.name = `${person.first_name} ${person.last_name}`;

        const func = isPersonAdmin(currentUser) ? onUpdatePerson : onUpdateMe;
        if (await func(person)) {
            props.onFinish?.(person);
        }
        setLoading(false);
        setOpen(false);
    };

    return (
        <>
            <Button icon={<EditOutlined />} shape={'circle'} onClick={() => setOpen(true)}></Button>
            <Modal
                title={'Edit Person'}
                open={open}
                onCancel={onCancel}
                footer={null}
                destroyOnClose
                maskClosable={true}
                width={500}
            >
                <PersonForm person={props.person} onFinish={onFinish} onCancel={onCancel} loading={loading} />
            </Modal>
        </>
    );
};

export default EditPersonButton;
