import { createContext, PropsWithChildren, useEffect, useState } from 'react';

import type { Field } from 'types';
import { useOrg } from 'hooks/useOrg';
import { fetchFields } from 'services/fields';
import { nameSort } from 'utils';

export type FieldContextProps = {
    fields: Field[];
    setFields: (fields: Field[] | ((fields: Field[] | undefined) => Field[])) => void;
};

export const FieldContext = createContext<FieldContextProps>({
    fields: [],
    setFields: () => {},
});

function useFieldState() {
    const org = useOrg();
    const [fields, setFields] = useState<Field[]>();

    useEffect(() => {
        if (!fields && org) {
            fetchFields(org)
                .then((fields) => setFields(fields))
                .catch((e) => console.log(e));
        }
    }, [fields, org]);

    return {
        fields: fields ? [...fields].sort(nameSort) : [],
        setFields,
    };
}

const FieldsProvider = (props: PropsWithChildren) => {
    const { fields, setFields } = useFieldState();
    return <FieldContext.Provider value={{ fields, setFields }}>{props.children}</FieldContext.Provider>;
};

export default FieldsProvider;
