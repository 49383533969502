import { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import type { Role, SearchResult } from 'types';
import { useOrg } from 'hooks/useOrg';
import DrawerDescription from 'components/drawers/common/DrawerDescription';
import apiClient from 'api';

const useAccountableFor = (roleId: string) => {
    const org = useOrg();
    const [accountableFor, setAccountableFor] = useState<SearchResult[]>();

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (org) {
                const results = await apiClient.get<SearchResult[]>(`/org/${org.id}/roles/${roleId}/to/ACCOUNTABLE_TO`);
                setAccountableFor(results);
            }
        };
        fetchSearchResults().catch((e) => console.log(e));
    }, [roleId, org]);

    return accountableFor;
};

const RoleAccountableFor = (props: { role: Role }) => {
    const accountableFor = useAccountableFor(props.role.id);
    if (!accountableFor || accountableFor.length === 0) return null;

    return (
        <DrawerDescription title={'Accountable For'}>
            {accountableFor.map((role) => (
                <Typography.Link
                    style={{ display: 'block' }}
                    key={role.id}
                    href={`/teams/${role.team_id}#role:${role.id}`}
                >
                    <Space>
                        <IdcardOutlined />
                        {role.name}
                    </Space>
                </Typography.Link>
            ))}
        </DrawerDescription>
    );
};

export default RoleAccountableFor;
