import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { fetchTeams, selectTeams, selectTeamStatus } from 'features/teamSlice';

import type { Person, Team } from 'types';
import { map, nameSort } from 'utils';
import { usePersonRoles } from './roles';

export function useTeams(): Team[] {
    const dispatch = useAppDispatch();
    const teams = useAppSelector(selectTeams);
    const teamStatus = useAppSelector(selectTeamStatus);

    useEffect(() => {
        if (teamStatus === 'idle') {
            dispatch(fetchTeams() as any);
        }
    }, [teamStatus, dispatch]);

    return [...teams].sort(nameSort);
}

export function useTeam(teamId: string | undefined) {
    const teams = useTeams();
    return teams.find((team) => team.id === teamId);
}

export function usePersonTeams(person: Person | null | undefined) {
    const result: Record<string, Team> = {};

    const teams = map(useTeams());
    const roles = usePersonRoles(person);

    for (const role of roles) {
        result[role.team.id] = teams[role.team.id];
    }
    return Object.keys(result)
        .map((key) => result[key])
        .sort(nameSort);
}
