import type { Organization, Person } from 'types';
import apiClient from 'api';

/* This is the main page loader. */
import { setCurrentUser } from 'features/currentUserSlice';
import { setOrg } from 'features/orgSlice';
import store from './store';

type Me = {
    person: Person;
    organizations: Organization[] | null;
    token: {
        access_token: string;
        token_type: 'bearer';
    };
};

export async function current() {
    const url = '/auth/me';

    const orgId = store.getState().org.value ? (store.getState().org.value as Organization).id : null;

    return apiClient.get<Me>(url).then((me) => {
        if (me.organizations) {
            let org = me.organizations.find((org) => org.id === orgId);
            org = org ? org : me.organizations[0];
            me.person.user_type = org.user_type;
            store.dispatch(setOrg(org));
        } // otherwise getOrg fails later for users not in an org (and not system)

        store.dispatch(setCurrentUser(me.person));
        apiClient.setToken(me.token.access_token);
        return me;
    });
}
