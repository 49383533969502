import { useState } from 'react';

import { Form, Input, Select } from 'antd';
import type { Field, MultiSelectField, OneToManyField, OneToOneField, SingleSelectField } from 'types';

import styles from './styles.module.scss';
import SettingsButtonBar from 'pages/settings/_common/SettingsButtonBar';

import SelectField from './SelectField';
import RelationshipField from './RelationshipField';
import { omit } from 'utils';

const FieldForm = (props: { field?: Field; onFinish: (field: Field) => void; onCancel?: () => void }) => {
    const [form] = Form.useForm<Field>();
    const fieldType = Form.useWatch('type', form);

    const [field, setField] = useState<Field>(props.field ? props.field : ({} as Field));

    function onFinish(values: Field) {
        values = omit(values, 'config');
        const newField = Object.assign({}, field, values);
        props.onFinish(newField);
    }

    async function onSetField(values: Field) {
        const newField = Object.assign({}, field, values);
        setField(newField);
        try {
            await form.validateFields();
        } catch (e) {}
    }

    return (
        <Form
            form={form}
            name="field"
            className={styles.form}
            initialValues={props.field}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<Field>
                label="Name"
                name="name"
                className="form-control"
                rules={[{ required: true, message: 'Please input a name.' }]}
            >
                <Input value={field.name} />
            </Form.Item>

            <Form.Item<Field>
                label="Field Type"
                name="type"
                className="form-control"
                rules={[{ required: true, message: 'Select a field type.' }]}
            >
                <Select
                    options={[
                        { value: 'text', label: 'Text' },
                        { value: 'single-select', label: 'Single Select' },
                        { value: 'multi-select', label: 'Multi Select' },
                        { value: 'one-to-one', label: 'One-To-One Relationship' },
                        { value: 'one-to-many', label: 'One-To-Many Relationship' },
                    ]}
                />
            </Form.Item>

            {fieldType === 'single-select' && <SelectField field={field as SingleSelectField} setField={onSetField} />}
            {fieldType === 'multi-select' && <SelectField field={field as MultiSelectField} setField={onSetField} />}
            {fieldType === 'one-to-one' && <RelationshipField field={field as OneToOneField} setField={onSetField} />}
            {fieldType === 'one-to-many' && <RelationshipField field={field as OneToManyField} setField={onSetField} />}

            <SettingsButtonBar onCancel={() => props.onCancel?.()} />
        </Form>
    );
};

export default FieldForm;
