import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const PasswordFormItem = () => {
    return (
        <Form.Item
            name="confirm_password"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The password that you entered does not match!'));
                    },
                }),
            ]}
        >
            <Input prefix={<LockOutlined />} type="password" placeholder="Confirm Password" />
        </Form.Item>
    );
};

export default PasswordFormItem;
