import { Link } from 'react-router-dom';

import { useThemeMode } from 'hooks/useThemeMode';

import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

import styles from './styles.module.scss';

const SiderLogo = () => {
    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;

    return (
        <div className={styles.logo}>
            <Link to={'/'}>
                <img src={src} alt={'Viggo'} />
            </Link>
        </div>
    );
};

export default SiderLogo;
