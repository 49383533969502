import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

function getDefaultFilters(): string[] {
    const values = localStorage.getItem('zoneFilters');
    if (values) {
        try {
            return JSON.parse(values) as string[];
        } catch {}
    }
    return [];
}

const zoneFilterSlice = createSlice({
    name: 'zoneFilters',
    initialState: {
        values: getDefaultFilters(),
    },
    reducers: {
        setZoneFilters: (state, action: PayloadAction<string[]>) => {
            localStorage.setItem('zoneFilters', JSON.stringify(action.payload));
            state.values = action.payload;
        },
    },
});

export const { setZoneFilters } = zoneFilterSlice.actions;
export const getZoneFilters = (state: RootState) => state.zoneFilters.values;

export default zoneFilterSlice.reducer;
