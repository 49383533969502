import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Typography, theme } from 'antd';

import styles from './ResetPasswordPage.module.scss';
import { getFirstQueryParam } from 'utils';
import ResetPasswordForm from './ResetPasswordForm';

import { useThemeMode } from 'hooks/useThemeMode';

import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

const ResetPasswordPage = () => {
    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const token = getFirstQueryParam('t');
    const [error, setError] = useState<string | null>(token ? null : 'Missing token');
    const [done, setDone] = useState(window.location.hash === '#reset');

    function onReset() {
        setDone(true);
    }

    function onError(_: number, message: string) {
        setError(message);
    }

    if (error || !token) {
        return (
            <Layout className={`auth-page ${styles.page} theme-${themeMode}`} style={{ background: colorBgContainer }}>
                <div className="auth-container">
                    <div className="login-logo">
                        <img src={src} alt={'Viggo'} />
                    </div>
                    <Typography.Text style={{ textAlign: 'center' }}>
                        Your reset password request couldn't be processed. Please try again.
                    </Typography.Text>
                    <Typography.Text className={'text-danger'}>[{error}]</Typography.Text>
                    <Link to={'/reset_password'}>Forgot Password</Link>
                </div>
            </Layout>
        );
    }

    if (done) {
        return (
            <Layout className={`auth-page ${styles.page} theme-${themeMode}`} style={{ background: colorBgContainer }}>
                <div className="auth-container">
                    <div className="login-logo">
                        <img src={src} alt={'Viggo'} />
                    </div>
                    <Typography.Title level={2}>Your password was successfully reset.</Typography.Title>
                    <Button type="primary" block href={'/'}>
                        Continue To Dashboard
                    </Button>
                </div>
            </Layout>
        );
    }

    return (
        <Layout className={`${styles.page} theme-${themeMode}`} style={{ background: colorBgContainer }}>
            <div className="auth-container">
                <div className="login-logo">
                    <img src={src} alt={'Viggo'} />
                </div>
                <ResetPasswordForm token={token} onReset={onReset} onError={onError} />
                <Typography.Text className="login-help-text">
                    Back to <Link to={'/login'}>Log In</Link>
                </Typography.Text>
            </div>
        </Layout>
    );
};

export default ResetPasswordPage;
