import { PropsWithChildren, ReactNode } from 'react';
import { Flex, Row } from 'antd';

import CardEmpty from 'components/cards/CardEmpty';
import styles from './styles.module.scss';

const Tab = (props: PropsWithChildren<{ extra?: ReactNode; empty?: boolean }>) => {
    return (
        <div className={styles.tab}>
            <Flex justify={'end'} className="FixTabBtn">
                {props.extra}
            </Flex>
            <Row gutter={16} wrap>
                {props.empty ? <CardEmpty /> : props.children}
            </Row>
        </div>
    );
};

export default Tab;
