import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import { Objective, Team } from 'types';
import { nameSort } from 'utils';
import { fetchObjectives, selectObjectives, selectObjectiveStatus } from 'features/objectiveSlice';

export function useObjectives(): Objective[] {
    const dispatch = useAppDispatch();
    const objectives = useAppSelector(selectObjectives);
    const objectiveStatus = useAppSelector(selectObjectiveStatus);

    useEffect(() => {
        if (objectiveStatus === 'idle') {
            dispatch(fetchObjectives() as any);
        }
    }, [objectiveStatus, dispatch]);

    return [...objectives].sort(nameSort);
}

export function useObjective(objectiveId: string | undefined) {
    const objectives = useObjectives();
    return objectiveId ? objectives.find((Objective) => Objective.id === objectiveId) : null;
}

export function useTeamObjectives(team: Team | null): Objective[] {
    const objectives = useObjectives();
    return team ? objectives.filter((objective) => objective.team.id === team.id) : [];
}
