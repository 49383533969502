import EditPersonButton from 'components/buttons/EditPersonButton';
import React from 'react';
import { Card, Descriptions, DescriptionsProps, Typography } from 'antd';

import styles from './styles.module.scss';
import { usePeople } from 'hooks/usePeople';
import { Person } from 'types';
import { map, userTypeDisplay } from 'utils';

const PeopleInfoCard = (props: { person: Person }) => {
    const people = map(usePeople());
    const reportsTo = props.person.reports_to_id ? people[props.person.reports_to_id] : null;

    const items: DescriptionsProps['items'] = [
        {
            key: 'email',
            label: 'Email',
            children: <Typography.Text>{props.person.email}</Typography.Text>,
            span: 'filled',
        },
    ];

    if (props.person.first_name) {
        items.push({
            key: 'firstName',
            label: 'First Name',
            children: <Typography.Text>{props.person.first_name}</Typography.Text>,
            span: 'filled',
        });
    }

    if (props.person.last_name) {
        items.push({
            key: 'lastName',
            label: 'Last Name',
            children: <Typography.Text>{props.person.last_name}</Typography.Text>,
            span: 'filled',
        });
    }

    if (props.person.city) {
        items.push({
            key: 'city',
            label: 'City',
            children: props.person.city,
            span: 'filled',
        });
    }

    if (props.person.state) {
        items.push({
            key: 'state',
            label: 'State',
            children: props.person.state,
            span: 'filled',
        });
    }

    if (props.person.country_code) {
        items.push({
            key: 'countryCode',
            label: 'Country',
            children: props.person.country_code,
            span: 'filled',
        });
    }

    if (props.person.timezone) {
        items.push({
            key: 'timezone',
            label: 'Timezone',
            children: props.person.timezone,
            span: 'filled',
        });
    }

    if (props.person.state) {
        items.push({
            key: 'status',
            label: 'Status',
            children: props.person.status,
            span: 'filled',
        });
    }

    if (props.person.title) {
        items.push({
            key: 'title',
            label: 'Title',
            children: <Typography.Text>{props.person.title}</Typography.Text>,
            span: 'filled',
        });
    }

    if (reportsTo) {
        items.push({
            key: 'reportsTo',
            label: 'Reports To',
            children: <Typography.Link href={`/people/${reportsTo.id}`}>{reportsTo.name}</Typography.Link>,
            span: 'filled',
        });
    }

    items.push({
        key: 'userType',
        label: 'User Type',
        children: <Typography.Text>{userTypeDisplay(props.person.user_type)}</Typography.Text>,
        span: 'filled',
    });

    return (
        <Card>
            <Descriptions
                className={styles.people}
                title="User Info"
                items={items}
                bordered
                extra={<EditPersonButton person={props.person} />}
            />
        </Card>
    );
};

export default PeopleInfoCard;
