import { Outlet } from 'react-router-dom';
import FieldsProvider from 'providers/FieldsProvider';

const SettingsPageElement = () => (
    <FieldsProvider>
        <Outlet />
    </FieldsProvider>
);

export default SettingsPageElement;
