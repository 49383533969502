import type { ObjectRef, TemplatedObject, TemplateField, TemplateNativeField } from 'types';
import { getField, isCustomTemplateField } from 'utils';

import { handleFormField } from 'components/forms/handlers';

import TextAreaFormItem from './TextAreaFormItem';
import SingleSelectFormItem from './SingleSelectFormItem';
import MultiSelectFormItem from './MultiSelectFormItem';
import OneToOneFormItem from './OneToOneFormItem';
import OneToManyFormItem from './OneToManyFormItem';

const FieldFormItem = (props: {
    templateField: TemplateField;
    obj: TemplatedObject & { [p: string]: any };
    onChange: (value: any) => void;
}) => {
    const isNativeField = !isCustomTemplateField(props.templateField);

    if (isNativeField) {
        const element = handleFormField(props.obj, props.templateField as TemplateNativeField, props.onChange);
        if (element !== undefined) {
            return element;
        }
    }

    const field = getField(props.templateField);

    if (field.type === 'text') {
        return (
            <TextAreaFormItem
                templateField={props.templateField}
                value={props.obj[field.name] as string}
                setValue={props.onChange}
                rows={4}
            />
        );
    }

    if (field.type === 'single-select') {
        return (
            <SingleSelectFormItem
                templateField={props.templateField}
                value={props.obj[field.name] as string}
                setValue={props.onChange}
            />
        );
    }

    if (field.type === 'multi-select') {
        return (
            <MultiSelectFormItem
                templateField={props.templateField}
                values={props.obj[field.name] as string[]}
                setValues={props.onChange}
            />
        );
    }

    if (field.type === 'one-to-one') {
        return (
            <OneToOneFormItem
                templateField={props.templateField}
                objectRef={props.obj[field.name] as ObjectRef}
                setObjectRef={props.onChange}
            />
        );
    }

    if (field.type === 'one-to-many') {
        return (
            <OneToManyFormItem
                templateField={props.templateField}
                objectRefs={props.obj[field.name] as ObjectRef[]}
                setObjectRefs={props.onChange}
            />
        );
    }

    return null;
};

export default FieldFormItem;
