import { Button, Tooltip } from 'antd';
import { ControlOutlined } from '@ant-design/icons';

const OpenDrawerButton = (props: { onClick: () => void; disabled?: boolean }) => {
    return (
        <Tooltip placement={'left'} title={'Open Drawer'}>
            <Button size={'small'} disabled={props.disabled} icon={<ControlOutlined />} onClick={props.onClick} />
        </Tooltip>
    );
};

export default OpenDrawerButton;
