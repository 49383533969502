import { Menu, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { ApartmentOutlined, DeploymentUnitOutlined } from '@ant-design/icons';

import type { Zone } from 'types';
import { useZones } from 'hooks/useZones';

import type { MenuItem, SiderMenuProps } from './types';
import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from 'store';
import { getZoneFilters, setZoneFilters } from 'features/zoneFilterSlice';

function isZoneChartSelected(selectedKeys: SiderMenuProps['selectedKeys']) {
    return selectedKeys?.length === 1 && selectedKeys[0] === 'zoneChart';
}

const ZonesSliders = () => {
    const zoneFilters = useAppSelector(getZoneFilters);
    const dispatch = useAppDispatch();

    const zones = useZones().filter((zone) => !zone.zone);
    if (!zones) return null;

    function onChange(zone: Zone, checked: boolean) {
        let filters = [...zoneFilters];
        if (checked) {
            filters = filters.filter((zoneId) => zoneId !== zone.id);
        } else {
            filters.push(zone.id);
        }
        dispatch(setZoneFilters(filters));
    }

    return (
        <div className={styles.switches}>
            {zones.map((zone) => (
                <div key={zone.id} className={styles.switch}>
                    <Switch
                        checked={!zoneFilters.includes(zone.id)}
                        onChange={(checked) => onChange(zone, checked)}
                        size={'small'}
                    />
                    <span>{zone.name}</span>
                </div>
            ))}
        </div>
    );
};

const ChartsMenu = (props: SiderMenuProps) => {
    const menuItems = [
        {
            key: 'orgChart',
            label: <Link to="/orgChart">Org Chart</Link>,
            icon: <ApartmentOutlined />,
        },
        {
            key: 'zoneChart',
            label: <Link to="/zoneChart">Zone Chart</Link>,
            icon: <DeploymentUnitOutlined />,
        },
    ] as MenuItem[];

    return (
        <>
            <Menu mode="inline" items={menuItems} selectedKeys={props.selectedKeys} onSelect={props.onSelect} />
            {isZoneChartSelected(props.selectedKeys) && <ZonesSliders />}
        </>
    );
};

export default ChartsMenu;
