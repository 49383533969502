import { TemplatedObject } from 'types';
import { map } from 'utils';
import templateSteps from './templateSteps';
import CreateEditSteps, { type CreateStepProps } from './CreateEditSteps';
import TemplateFormItem from 'components/forms/common/TemplateFormItem';
import { useTemplates } from 'hooks/useTemplates';
import { capitalCase } from 'change-case';

const GeneralSteps = <T extends TemplatedObject>(props: {
    obj: T;
    setObj: (obj: T) => void;
    onFinish: (obj: T) => void;
    onCancel: () => void;
    loading?: boolean;
    skip?: string[];
}) => {
    const templates = map(useTemplates(props.obj.type));
    const template = props.obj.template_id ? templates[props.obj.template_id] : undefined;

    const steps: CreateStepProps[] = [
        {
            title: `${capitalCase(props.obj.type)} Template`,
            content: (
                <TemplateFormItem
                    objectType={props.obj.type}
                    templateId={props.obj.template_id || ''}
                    setTemplate={(templateId: string) => {
                        props.setObj({ ...props.obj, template_id: templateId });
                    }}
                />
            ),
            valid: () => true,
        },
    ];

    if (template) {
        steps.push(...templateSteps(props.obj, props.setObj, template, props.skip));
    }

    return (
        <CreateEditSteps
            steps={steps}
            onCreate={() => props.onFinish(props.obj)}
            onCancel={props.onCancel}
            loading={props.loading}
        />
    );
};

export default GeneralSteps;
