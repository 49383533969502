import { Tabs, TabsProps } from 'antd';

import { Team, TemplatedObject } from 'types';
import RolesTab from './RolesTab';
import ObjectivesTab from 'pages/teams/cards/ObjectivesTab';
import DecisionsTab from './DecisionsTab';

const TeamDetailCard = (props: { team: Team; onClick?: (obj: TemplatedObject) => void }) => {
    const items: TabsProps['items'] = [
        {
            key: 'roles',
            label: 'Roles',
            children: <RolesTab team={props.team} onClick={props.onClick} />,
        },
        {
            key: 'decisions',
            label: 'Decisions',
            children: <DecisionsTab team={props.team} onClick={props.onClick} />,
        },
        {
            key: 'objectives',
            label: 'Objectives',
            children: <ObjectivesTab team={props.team} onClick={props.onClick} />,
        },
        /*
        {
            key: 'agreements',
            label: 'Agreements',
            children: <AgreementsTab team={props.team} onClick={props.onClick} />,
        },
        */
    ];

    return <Tabs defaultActiveKey="roles" items={items} />;
};

export default TeamDetailCard;
