import { App, Form, Button, Typography } from 'antd';

import { baseUrl, getErrorMessage } from 'utils';
import { API_URL } from 'api';
import EmailFormItem from 'pages/auth/_common/EmailFormItem';

type ForgotPasswordRequest = {
    email: string;
};

const ForgotPasswordForm = (props: { onFinish: (email: string) => void }) => {
    const { message } = App.useApp();

    async function onFinish(values: ForgotPasswordRequest) {
        try {
            const res = await fetch(`${API_URL}/auth/forgot_password`, {
                method: 'POST',
                body: JSON.stringify({ ...values, base_url: baseUrl() }),
                headers: { 'Content-Type': 'application/json' },
            });
            if (res.status !== 200) {
                message.error(res.statusText);
            } else {
                props.onFinish(values.email);
            }
        } catch (e) {
            message.error(
                <>
                    <b>[Unknown Network Error]</b> {getErrorMessage(e)}
                </>,
            );
        }
    }

    return (
        <Form
            name="forgot-password"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title level={2}>Forgot Password</Typography.Title>
            <EmailFormItem extra={'Enter the email that you used to sign up.'} />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="forgot-password-form-button">
                    Send Reset Email
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ForgotPasswordForm;
