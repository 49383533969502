import { useCurrentUser } from 'hooks/useCurrentUser';
import { PropsWithChildren } from 'react';
import { App, Button, Flex, Modal, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import type { AgendaItem, Meeting } from 'types';
import { getErrorMessage, isPersonViewer } from 'utils';
import { useOrg } from 'hooks/useOrg';
import { useDeleteMeetingAgendaItem } from 'hooks/meetings';
import { deleteAgendaItem } from 'services/meetings';

const DeleteAgendaItemButton = (
    props: PropsWithChildren<{
        meeting: Meeting;
        agendaItem: AgendaItem;
        onDelete?: (agendaItem: AgendaItem) => void;
        confirm?: boolean;
    }>,
) => {
    const { message } = App.useApp();
    const deleteMeetingAgendaItem = useDeleteMeetingAgendaItem(props.meeting);

    const org = useOrg();

    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    const {
        token: { colorError },
    } = theme.useToken();

    async function onOk() {
        try {
            const agendaItem = await deleteAgendaItem(org!, props.agendaItem.id);
            deleteMeetingAgendaItem(agendaItem);

            message.success('The agenda item was successfully deleted.');
            props.onDelete?.(props.agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onConfirm() {
        return Modal.confirm({
            title: 'Confirm Agenda Item Deletion',
            content: 'Are you sure you want to permanently delete this agenda item?  This operation cannot be undone.',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
            okText: 'Delete Agenda Item',
            okButtonProps: { type: 'default', danger: true },
            icon: <CloseCircleOutlined style={{ color: colorError }} />,
            onOk,
        });
    }

    return (
        <Flex>
            <Button danger onClick={props.confirm ? onConfirm : onOk}>
                {props.children ? props.children : 'Delete Agenda Item'}
            </Button>
        </Flex>
    );
};

export default DeleteAgendaItemButton;
