import showdown from 'showdown';

const MarkdownDiv = (props: { markdown: string }) => {
    if (!props.markdown) {
        return null;
    }

    const converter = new showdown.Converter();
    return <div className="description" dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.markdown) }} />;
};

export default MarkdownDiv;
