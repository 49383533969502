import type {
    MultiSelectField,
    OneToManyField,
    OneToOneField,
    SingleSelectField,
    TemplatedObject,
    TemplateField,
    TemplateNativeField,
} from 'types';
import { getField, isCustomTemplateField } from 'utils';
import { handleDrawerField } from 'components/drawers/handlers';

import TextFieldDrawerDescription from './TextFieldDrawerDescription';
import SingleSelectFieldDrawerDescription from './SingleSelectFieldDrawerDescription';
import MultiSelectFieldDrawerDescription from './MultiSelectFieldDrawerDescription';
import OneToOneFieldDrawerDescription from './OneToOneFieldDrawerDescription';
import OneToManyFieldDrawerDescription from './OneToManyFieldDrawerDescription';

const DrawerField = (props: { obj: TemplatedObject; templateField: TemplateField }) => {
    const isNativeField = !isCustomTemplateField(props.templateField);

    if (isNativeField) {
        const element = handleDrawerField(props.obj, props.templateField as TemplateNativeField);
        if (element !== undefined) {
            return element;
        }
    }

    const field = getField(props.templateField);
    if (field.type === 'text') {
        return <TextFieldDrawerDescription obj={props.obj} templateField={props.templateField} />;
    }
    if (field.type === 'single-select') {
        return <SingleSelectFieldDrawerDescription obj={props.obj} field={field as SingleSelectField} />;
    }
    if (field.type === 'multi-select') {
        return <MultiSelectFieldDrawerDescription obj={props.obj} field={field as MultiSelectField} />;
    }
    if (field.type === 'one-to-one') {
        return <OneToOneFieldDrawerDescription obj={props.obj} field={field as OneToOneField} />;
    }
    if (field.type === 'one-to-many') {
        return <OneToManyFieldDrawerDescription obj={props.obj} field={field as OneToManyField} />;
    }
    return null;
};

export default DrawerField;
