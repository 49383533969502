import { updateDecision } from 'features/decisionSlice';
import { updateObjective } from 'features/objectiveSlice';
import { updateRole } from 'features/roleSlice';
import { updateTeam } from 'features/teamSlice';
import { updateZone } from 'features/zoneSlice';

import { AppDispatch, useAppDispatch } from 'store';
import type { Decision, Objective, Role, Team, TemplatedObject, Zone } from 'types';

export function useUpdateDispatch() {
    const dispatch: AppDispatch = useAppDispatch();

    return (obj: TemplatedObject) => {
        switch (obj.type) {
            case 'decision':
                return dispatch(updateDecision(obj as Decision));
            case 'objective':
                return dispatch(updateObjective(obj as Objective));
            case 'role':
                return dispatch(updateRole(obj as Role));
            case 'team':
                return dispatch(updateTeam(obj as Team));
            case 'zone':
                return dispatch(updateZone(obj as Zone));
        }

        return { error: true, payload: { detail: `Invalid object type: ${obj.type}` } };
    };
}
