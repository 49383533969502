import { Typography } from 'antd';
import { Objective } from 'types';
import CardLink from 'components/cards/CardLink';

import styles from './styles.module.scss';

const ObjectiveCard = (props: { objective: Objective; onClick?: () => void }) => {
    return (
        <CardLink className={styles.card} onClick={props.onClick}>
            <Typography.Title level={3}>{props.objective.name}</Typography.Title>
        </CardLink>
    );
};

export default ObjectiveCard;
