import * as d3 from 'd3';

// import forceBoundary from 'd3-force-boundary';
import { FDGLink, FDGNode } from './types';
import { defaultRadius } from './utils';

function simulate(props: {
    width: number;
    height: number;
    data: { nodes: FDGNode[]; links: FDGLink[] };
    radius?: number;
}) {
    const nodes = props.data.nodes.map((d) => ({ ...d }));
    const links = props.data.links.map((d) => ({ ...d }));
    const radius = defaultRadius(props.width, props.data.nodes.length, props.radius);

    const simulation = d3
        .forceSimulation<FDGNode, FDGLink>(nodes)
        // Force #1: links between nodes
        .force(
            'link',
            d3
                .forceLink<FDGNode, FDGLink>(links)
                .distance(50)
                .strength(1)
                .id((d) => d.id),
        )

        // Force #2: avoid node overlaps
        //.force('collide', d3.forceCollide().radius(RADIUS))
        .force(
            'collide',
            d3.forceCollide().radius(function () {
                return radius + 1;
            }),
        )

        // Force #3: attraction or repulsion between nodes
        .force('charge', d3.forceManyBody())

        // Force #4: nodes are attracted by the center of the chart area
        .force('center', d3.forceCenter(props.width / 2, props.height / 2))

        //.force('x', d3.forceX(props.width / 2).strength(0.5))
        //.force('y', d3.forceY(props.height / 2).strength(0.5))

        // Keep them in the boundary.
        //.force('boundary', forceBoundary(0,0, props.width, props.height))

        .stop();

    simulation.tick(300);
    return { nodes, links };
}

export default simulate;
