import { CPGNode } from './types';

import type { HierarchyCircularNode, ZoomView } from 'd3';
import BaseTooltip from 'components/canvas/common/tooltip/BaseTooltip';

const CPGTooltip = (props: {
    canvas: HTMLCanvasElement | null;
    node: HierarchyCircularNode<CPGNode> | null | undefined;
    onTooltipText: (node: CPGNode | undefined) => string | null | undefined;
    zoomView: ZoomView;
    width: number;
    height: number;
}) => {
    if (!props.canvas || !props.node) return null;

    const dimension = Math.min(props.width, props.height);
    const centerX = props.width / 2;
    const centerY = props.height / 2;
    const k = dimension / props.zoomView[2];

    const x = centerX + (props.node.x - props.zoomView[0]) * k;
    const y = centerY + (props.node.y - props.zoomView[1]) * k;
    const r = props.node.r * k;

    if (y - r < 1) return null;

    return <BaseTooltip canvas={props.canvas} text={props.onTooltipText(props.node.data)} x={x} y={y - r} />;
};

export default CPGTooltip;
