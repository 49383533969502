import React, { useState } from 'react';
import { Person } from 'types';
import OrgChartComponent, { UserDatum } from 'pages/org/OrgChartComponent';
import PersonSelect from './PersonSelect';

function firstKey(data: Record<string, UserDatum[]>) {
    const keys = Object.keys(data);
    return keys.length > 0 ? keys[0] : '';
}

const OrgChartContainer = (props: {
    data: Record<string, UserDatum[]>;
    people: Record<string, Person>;
    onClick: (personId: string) => void;
}) => {
    const [personId, setPersonId] = useState<string>(firstKey(props.data));

    if (personId && !props.data[personId]) {
        setPersonId(firstKey(props.data));
    }

    return (
        <>
            {Object.keys(props.data).length > 1 ? (
                <PersonSelect
                    personId={personId}
                    setPersonId={setPersonId}
                    people={Object.keys(props.data).map((id) => props.people[id])}
                />
            ) : null}
            <OrgChartComponent data={props.data[personId]} onClick={props.onClick} />;
        </>
    );
};

export default OrgChartContainer;
