import React from 'react';

import { UIMatch, useNavigation } from 'react-router-dom';
import { useMatches } from 'react-router-dom';

import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

import { isString, trimSlashes } from 'utils';

import { useOrg } from 'hooks/useOrg';
import Loading from 'components/Loading';
import SelectOrgPage from './selectOrg/SelectOrgPage';

import MainLayout from './MainLayout';

type RouteHandle = {
    className?: string;
    title?: string;
    breadcrumbs?: (data: any) => ItemType[];
};

function parseMatches(matches: UIMatch[]) {
    const lastRoute = matches[matches.length - 1];
    const handle = lastRoute.handle as RouteHandle | undefined;

    return {
        title: isString(handle?.title) ? trimSlashes(handle?.title) : handle?.title,
        className: handle?.className,
    };
}

function breadCrumbItems(_matches: UIMatch[]) {
    // const lastRoute = matches[matches.length - 1];
    // FIXME: build breadcrumbs from lastRoute.params
    return undefined;
}

const PageElement = () => {
    const matches = useMatches();
    const breadcrumbs = breadCrumbItems(matches);
    const { title, className } = parseMatches(matches);
    const navigation = useNavigation();
    const org = useOrg();

    if (navigation.state === 'loading') {
        return <Loading />;
    }
    return org ? <MainLayout className={className} pageTitle={title} breadcrumbs={breadcrumbs} /> : <SelectOrgPage />;
};

export default PageElement;
