import { Form, Select } from 'antd';
import { SingleSelectField, TemplateField } from 'types';
import { fieldLabel, getField } from 'utils';

const SingleSelectFormItem = (props: {
    templateField: TemplateField;
    value: string | undefined;
    setValue: (value: string | null) => void;
}) => {
    const field = getField(props.templateField) as SingleSelectField;
    const options = field.config.options.map((option) => {
        return { label: option, value: option };
    });

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(field.name)}
            rules={[{ required: props.templateField.required, message: 'Select a value.' }]}
            name={field.name}
            initialValue={props.value}
        >
            <Select
                options={options}
                onChange={(value) => (value ? props.setValue(value) : props.setValue(null))}
                allowClear={!props.templateField.required}
            />
        </Form.Item>
    );
};

export default SingleSelectFormItem;
