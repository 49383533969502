import { Avatar, Card, Typography } from 'antd';
import { Organization } from 'types';

import { initials } from 'utils';

const SelectOrgCard = (props: { org: Organization; onClick?: (org: Organization) => void }) => {
    return (
        <Card className="org-card" onClick={() => props.onClick?.(props.org)}>
            <div className="org-card-icon">
                <Avatar shape={'square'} alt={props.org.name} src={props.org.picture_medium} size={100}>
                    {props.org.picture_medium ? '' : initials(props.org.name)}
                </Avatar>
            </div>
            <Typography.Title level={3}>{props.org.name}</Typography.Title>
        </Card>
    );
};

export default SelectOrgCard;
