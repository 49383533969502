import { Flex, Steps, type StepsProps } from 'antd';

import styles from './styles.module.scss';
import type { Meeting } from 'types';
import TeamMeetingSelector from './TeamMeetingSelector';

const TeamMeetingsCardHeader = (props: {
    meeting: Meeting | undefined;
    setMeeting: (meeting: Meeting) => void;
    meetings: Meeting[];
}) => {
    if (!props.meeting) return null;

    const items = props.meeting?.elements.map((element) => {
        return {
            title: element.title,
            description: element.duration,
            status: 'finish',
        } as StepsProps;
    });

    return (
        <Flex align={'center'} justify={'space-between'} gap={32} wrap={'wrap-reverse'} style={{ width: '100%' }}>
            {props.meeting.elements.length > 0 && (
                <Steps className={styles.steps} size={'small'} items={items} progressDot type={'default'} />
            )}
            <TeamMeetingSelector
                className={styles.selector}
                meeting={props.meeting}
                setMeeting={props.setMeeting}
                meetings={props.meetings}
            />
        </Flex>
    );
};

export default TeamMeetingsCardHeader;
