import handleRole from './role';
import { Role, TemplatedObject, TemplateNativeField } from 'types';

export function handleFormField(
    obj: TemplatedObject,
    templateField: TemplateNativeField,
    onChange: (value: any) => void,
) {
    if (obj.type === 'role') {
        return handleRole(obj as Role, templateField, onChange);
    }
    return undefined;
}
