import { App, Form, Button, Typography } from 'antd';

import type { Person } from 'types';
import { getErrorMessage } from 'utils';
import { API_URL } from 'api';

import PasswordFormItem from 'pages/auth/_common/PasswordFormItem';
import PasswordConfirmFormItem from 'pages/auth/_common/PasswordConfirmFormItem';

type ResetPasswordRequest = {
    password: string;
};

const ResetPasswordForm = (props: {
    token: string;
    onReset: (data: Person) => void;
    onError?: (status: number, message: string) => void;
}) => {
    const { message } = App.useApp();

    async function onFinish(values: ResetPasswordRequest) {
        try {
            const res = await fetch(`${API_URL}/auth/reset_password`, {
                method: 'POST',
                body: JSON.stringify({
                    token: props.token,
                    password: values.password,
                }),
                headers: { 'Content-Type': 'application/json' },
            });
            if (res.status !== 200) {
                const error = await res.json();
                const errorMessage = error.detail ? error.detail : res.statusText;
                props.onError ? props.onError(res.status, errorMessage) : message.error(res.statusText);
            } else {
                props.onReset((await res.json()) as Person);
            }
        } catch (e) {
            message.error(
                <>
                    <b>[Unknown Network Error]</b> {getErrorMessage(e)}
                </>,
            );
        }
    }

    return (
        <Form
            name="register"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Reset Password</Typography.Title>
            <PasswordFormItem />
            <PasswordConfirmFormItem />

            <Form.Item>
                <Button type="primary" htmlType="submit" className="register-form-button">
                    Reset Password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPasswordForm;
