import type { ThemeMode } from 'types';
import type { CPGNode } from './types';
import type { HierarchyCircularNode, ZoomView } from 'd3';
import { generateColor } from 'utils';

type DrawProps = {
    context: CanvasRenderingContext2D;
    shadowContext: CanvasRenderingContext2D;
    imageMap: Map<string, HTMLImageElement>;

    rootNode: HierarchyCircularNode<CPGNode>;
    count: number;

    width: number;
    height: number;

    zoomView: ZoomView;
    mode: ThemeMode;
};

type DrawnNode = HierarchyCircularNode<CPGNode> & {
    isVisible: boolean;
    showText: boolean;
    depth: number;
};

function drawNode(
    drawProps: DrawProps,

    node: HierarchyCircularNode<CPGNode>,
    centerX: number,
    centerY: number,

    k: number,

    uniqueColor: string,
) {
    const x = centerX + (node.x - drawProps.zoomView[0]) * k;
    const y = centerY + (node.y - drawProps.zoomView[1]) * k;
    const r = node.r * k;

    drawProps.context.beginPath();
    drawProps.context.moveTo(x + r, y);
    drawProps.context.arc(x, y, r, 0, 2 * Math.PI);

    drawProps.context.fillStyle = node.data.fillStyle ? node.data.fillStyle : '#fff';
    drawProps.context.strokeStyle = node.data.strokeStyle ? node.data.strokeStyle : '#000';
    drawProps.context.lineWidth = node.data.lineWidth ? node.data.lineWidth : 1;
    drawProps.context.fill();
    drawProps.context.stroke();

    if (node.data.src) {
        const img = drawProps.imageMap.get(node.data.src);
        if (img) {
            /* See the MDN note: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/clip */
            drawProps.context.save();
            drawProps.context.beginPath();
            drawProps.context.moveTo(x + r, y);
            drawProps.context.arc(x, y, r, 0, 2 * Math.PI);
            drawProps.context.clip();
            drawProps.context.drawImage(img, x - r, y - r, 2 * r, 2 * r);
            drawProps.context.restore();
        }
    }

    drawProps.shadowContext.beginPath();
    drawProps.shadowContext.moveTo(x + r, y);
    drawProps.shadowContext.arc(x, y, r, 0, 2 * Math.PI);
    drawProps.shadowContext.strokeStyle = uniqueColor;
    drawProps.shadowContext.fillStyle = uniqueColor;
    drawProps.shadowContext.fill();
    drawProps.shadowContext.stroke();

    const isVisible = x + r < drawProps.width && x - r > 0 && y + r < drawProps.height && y - r > 0;
    const drawnNode = node as DrawnNode;
    drawnNode.isVisible = isVisible;
}

function wrapText(
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    lineWidth: number,
    lineHeight: number,
) {
    let line = '';
    const paragraphs = text.split('\n');
    const lines = [];

    for (let i = 0; i < paragraphs.length; i++) {
        const words = paragraphs[i].split(' ');
        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > lineWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }
        lines.push(line);
        line = '';
    }

    const height = lines.length * lineHeight;
    y -= height / 2 - lineHeight;

    for (let i = 0; i < lines.length; i++) {
        context.fillText(lines[i], x, y);
        y += lineHeight;
    }
}

function draw(props: DrawProps) {
    const centerX = props.width / 2;
    const centerY = props.height / 2;

    const diameter = Math.min(props.width, props.height);
    const k = diameter / props.zoomView[2];

    props.context.clearRect(0, 0, props.width, props.height);
    props.shadowContext.clearRect(0, 0, props.width, props.height);

    props.context.textAlign = 'center';

    props.rootNode
        .descendants()
        .slice(1)
        .map((node, index) => {
            const uniqueColor = generateColor(index, props.count);
            return drawNode(props, node, centerX, centerY, k, uniqueColor);
        });

    props.context.fillStyle = props.mode === 'dark' ? '#fff' : '#000';

    let depth = Infinity; // depth needs to be per top-level child?
    props.rootNode
        .descendants()
        .slice(1)
        .map((node) => {
            const drawnNode = node as DrawnNode,
                parentNode = node.parent as DrawnNode;

            if (drawnNode.isVisible && !parentNode.isVisible) {
                depth = Math.min(drawnNode.depth, depth);
                if (drawnNode.depth === depth || parentNode.r * k * 2 === diameter) {
                    const x = centerX + (node.x - props.zoomView[0]) * k;
                    const y = centerY + (node.y - props.zoomView[1]) * k;
                    const d = node.r * k * 2;

                    const fontSize = (16 * diameter) / (props.rootNode.r * 2);
                    props.context.font = `bold ${fontSize}px sans-serif`;

                    wrapText(props.context, node.data.title, x, y, d, fontSize);
                    return true;
                }
            }
            return false;
        });
}

export default draw;
