import React, { createContext, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

import type { AgendaItem, Meeting, Team, WebSocketMessage } from 'types';
import { useSubscribe } from 'hooks/pubsub';
import { useOrg } from 'hooks/useOrg';
import { fetchMeetings } from 'services/meetings';
import { isDebug, nameSort, nSort } from 'utils';

export type TeamMeetingContextProps = {
    meetings: Meeting[];
    setTeamMeetings: (teamMeetings: Meeting[] | ((teamMeeting: Meeting[] | undefined) => Meeting[])) => void;
};

export const TeamMeetingContext = createContext<TeamMeetingContextProps>({
    meetings: [],
    setTeamMeetings: () => {},
});

/*
 * It's ugly to find meetings by searching columns but there should only be
 * a small number of meetings/columns, so it should be faster in practice than mapping.
 */
function findMeetingByColumn(meetings: Meeting[] | undefined, columnId: string) {
    if (meetings) {
        for (const meeting of meetings) {
            const column = meeting.columns.find((c) => c.id === columnId);
            if (column) {
                return meeting;
            }
        }
    }
    return undefined;
}

function handleMeetingAgendaItem(meeting: Meeting, action: WebSocketMessage['action'], agendaItem: AgendaItem) {
    const newMeeting = { ...meeting };
    for (const column of newMeeting.columns) {
        const agendaItems = column.items.filter((i) => i.id !== agendaItem.id);
        if (column.id === agendaItem.meeting_column_id && action !== 'delete') {
            agendaItems.push(agendaItem);
        }
        agendaItems.sort(nSort);
        column.items = agendaItems;
    }
    return newMeeting;
}

function dedupAgendaItems(meetings: Meeting[] | undefined) {
    if (meetings) {
        for (const meeting of meetings) {
            const map: Record<string, AgendaItem> = {};
            for (const column of meeting.columns) {
                const agendaItems: AgendaItem[] = [];

                for (const agendaItem of column.items) {
                    if (map[agendaItem.id]) {
                        console.log('Duplicate agenda item', column);
                        console.trace();
                    } else {
                        map[agendaItem.id] = agendaItem;
                        agendaItems.push(agendaItem);
                    }
                }

                column.items = agendaItems;
            }
        }
    }
}

function useMeetingState(team: Team) {
    const org = useOrg();
    const [meetings, _setTeamMeetings] = useState<Meeting[]>();
    const lastMessage = useSubscribe();

    const setTeamMeetings = (newValue: Meeting[] | SetStateAction<Meeting[]>) => {
        const debug = isDebug('meetings');

        if (typeof newValue === 'function') {
            // If newValue is a function, call it with the current state
            _setTeamMeetings((prevState) => {
                if (debug) {
                    dedupAgendaItems(prevState);
                }

                const result = newValue(prevState || []);
                if (debug) {
                    console.log('Previous meetings state:', prevState);
                    console.log('New meetings state:', result);
                }
                return result;
            });
        } else {
            if (debug) {
                dedupAgendaItems(newValue);
                console.log('Previous meeting state:', meetings);
                console.log('New meeting state:', newValue);
            }
            _setTeamMeetings(newValue);
        }
    };

    /* team changed, clear meetings */
    useEffect(() => {
        _setTeamMeetings(undefined);
    }, [team.id]);

    /* fetch */
    useEffect(() => {
        if (!meetings && org) {
            fetchMeetings(org, team.id)
                .then((meetings) => setTeamMeetings(meetings))
                .catch((e) => console.log(e));
        }
        // The next line just allows us to use the debugging code - It can be removed with that code.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetings, org, team.id]);

    /* websocket meeting update */
    useEffect(() => {
        if (lastMessage) {
            setTeamMeetings((meetings) => {
                if (meetings) {
                    if (lastMessage.type === 'meeting') {
                        const meeting = lastMessage.data as Meeting;
                        if (meeting.team_id === team.id) {
                            const newMeetings = meetings.filter((m) => m.id !== meeting.id);
                            if (lastMessage.action !== 'delete') {
                                newMeetings.push(meeting);
                            }
                            return newMeetings;
                        }
                    }
                }
                return meetings;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage, team.id]);

    /* websocket agenda item change */
    useEffect(() => {
        if (lastMessage) {
            setTeamMeetings((meetings) => {
                if (meetings) {
                    if (lastMessage.type === 'agenda_item') {
                        const agendaItem = lastMessage.data as AgendaItem;

                        const meeting = findMeetingByColumn(meetings, agendaItem.meeting_column_id);
                        if (meeting) {
                            const newMeeting = handleMeetingAgendaItem(meeting, lastMessage.action, agendaItem);
                            const newMeetings = meetings.filter((meeting) => meeting.id !== newMeeting.id);
                            newMeetings.push(newMeeting);
                            return newMeetings;
                        }
                    }
                }
                return meetings;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage, team.id]);

    return {
        meetings: meetings ? [...meetings].sort(nameSort) : [],
        setTeamMeetings,
    };
}

const TeamMeetingProvider = (
    props: PropsWithChildren<{
        team: Team;
    }>,
) => {
    const { meetings, setTeamMeetings } = useMeetingState(props.team);
    return (
        <TeamMeetingContext.Provider value={{ meetings, setTeamMeetings }}>
            {props.children}
        </TeamMeetingContext.Provider>
    );
};

export default TeamMeetingProvider;
