import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import type { Team } from 'types';
import { asDataSource, map } from 'utils';
import { useTeams } from 'hooks/teams';
import { useRoles } from 'hooks/roles';
import CreateRoleButton from 'components/buttons/CreateRoleButton';

function createColumns(teams: Record<string, Team>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, { id, team }) => <Link to={`/teams/${team.id}#role:${id}`}>{text}</Link>,
        },
        {
            title: 'Team',
            key: 'team',
            render: (_, { team }) => {
                const parentTeam = team?.id ? teams[team.id] : null;

                if (!parentTeam) {
                    return team.id;
                }

                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <Link to={`/zones/${parentTeam.id}`}>{parentTeam.name}</Link>
                    </Flex>
                );
            },
        },
        {
            title: 'Accountabilities',
            dataIndex: 'accountabilities',
            key: 'accountabilities',
        },
    ];
    return columns;
}

const RolesListPage = () => {
    const roles = useRoles();
    const teams = map(useTeams());
    return (
        <Space className={'w-100'} size={'small'} direction={'vertical'}>
            <Table columns={createColumns(teams)} dataSource={asDataSource(roles)} pagination={false} />
            <CreateRoleButton />
        </Space>
    );
};

export default RolesListPage;
