import { fieldLabel } from 'utils';
import { BaseObject, SingleSelectField } from 'types';
import DrawerDescription from './DrawerDescription';

const SingleSelectFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any };
    field: SingleSelectField;
}) => {
    const value = props.obj[props.field.name];
    if (!value) {
        return null;
    }

    return <DrawerDescription title={fieldLabel(props.field.name)}>{value}</DrawerDescription>;
};

export default SingleSelectFieldDrawerDescription;
