import { useEffect, useState } from 'react';
import type { HierarchyCircularNode } from 'd3';
import type { CPGNode } from './types';
import { loadImages } from './utils';

export function useImages(root: HierarchyCircularNode<CPGNode> | undefined) {
    const [images, setImages] = useState<Map<string, HTMLImageElement>>();

    useEffect(() => {
        if (root) {
            loadImages(root).then((images) => {
                const map = new Map<string, HTMLImageElement>();
                for (const image of images) {
                    map.set(image.src, image);
                }
                setImages(map);
            });
        }
    }, [root, setImages]);

    return images;
}
