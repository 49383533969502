import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useDecisions } from 'hooks/useDecisions';
import { asDataSource } from 'utils';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, { id, team }) => {
            return <Link to={`/teams/${team.id}#decision:${id}`}>{text}</Link>;
        },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    /*
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
     */
];

const DecisionsListPage = () => {
    const decisions = useDecisions();
    return <Table columns={columns} dataSource={asDataSource(decisions)} pagination={false} />;
};

export default DecisionsListPage;
