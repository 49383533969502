import { useObjects } from 'hooks/useObjects';
import { useState } from 'react';
import { Drawer } from 'antd';

import type { ObjectType, TemplatedObject } from 'types';

import styles from './styles.module.scss';
import { titleCase } from 'utils';

import ObjectDrawerContents from './ObjectDrawerContents';
import ObjectDrawerButtons from './ObjectDrawerButtons';

const ObjectDrawer = (props: { objectId: string; objectType: ObjectType; onClose: () => void }) => {
    const [edit, setEdit] = useState(false);

    const objects = useObjects([props.objectType]);
    const obj = objects[props.objectType].find((obj) => obj.id === props.objectId);
    if (!obj) return null;

    const name = obj ? titleCase(obj.type) : '';

    function onClose() {
        setEdit(false);
        props?.onClose();
    }

    return (
        <Drawer
            className={styles.drawer}
            title={obj.name}
            onClose={onClose}
            open={true}
            closable={false}
            extra={<ObjectDrawerButtons name={name} edit={edit} setEdit={setEdit} onClose={onClose} />}
            destroyOnClose
        >
            <ObjectDrawerContents edit={edit} setEdit={setEdit} onClose={onClose} obj={obj as TemplatedObject} />
        </Drawer>
    );
};

export default ObjectDrawer;
