import TeamMeetingProvider from 'providers/TeamMeetingProvider';
import { PropsWithChildren } from 'react';
import { Team } from 'types';
import { isTeamPage } from 'utils';

const TeamProvider = (props: PropsWithChildren<{ team: Team }>) => {
    if (isTeamPage(props.team.id)) {
        return <>{props.children}</>;
    }
    return <TeamMeetingProvider team={props.team}>{props.children}</TeamMeetingProvider>;
};

export default TeamProvider;
