import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useThemeMode } from 'hooks/useThemeMode';
import type { Person } from 'types';
import { initials } from 'utils';

const PersonAvatar = (props: { person?: Person }) => {
    const mode = useThemeMode();
    const color = mode === 'dark' ? 'rgb(140, 140, 140)' : 'rgba(0, 0, 0, 0.25)';
    if (!props.person) {
        return (
            <Avatar
                style={{ border: `1px solid ${color}`, color, backgroundColor: 'transparent' }}
                icon={<UserOutlined />}
            />
        );
    }
    if (props.person.picture_small) {
        return <Avatar src={props.person.picture_small} />;
    }
    return <Avatar>{initials(props.person.name)}</Avatar>;
};

export default PersonAvatar;
