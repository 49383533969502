import apiClient from 'api';
import type { Organization, Create, Field } from 'types';

export async function fetchFields(org: Organization) {
    return apiClient.get<Field[]>(`/org/${org.id}/fields`);
}

export async function createField(org: Organization, field: Create<Field>) {
    return apiClient.post<Field>(`/org/${org.id}/fields`, field);
}

export async function updateField(org: Organization, field: Field) {
    const { id, ...update } = field;
    return apiClient.patch<Field>(`/org/${org.id}/fields/${id}`, update);
}

export async function deleteField(org: Organization, fieldId: string) {
    return apiClient.delete<Field>(`/org/${org.id}/fields/${fieldId}`);
}
