import { Field } from 'types';
import { Space, Tag } from 'antd';

const FieldOptionDisplay = (props: { field: Field }) => {
    if (props.field.type === 'single-select' || props.field.type === 'multi-select') {
        return (
            <Space size={'small'} wrap>
                {props.field.config.options?.map((option, index) => (
                    <Tag key={index} className="field-value">
                        {option}
                    </Tag>
                ))}
            </Space>
        );
    }

    if (props.field.type === 'one-to-one' || props.field.type === 'one-to-many') {
        return (
            <Space size={'small'} wrap>
                {props.field.config.allowed?.map((value, index) => (
                    <Tag key={index} className="field-value">
                        {value}
                    </Tag>
                ))}
            </Space>
        );
    }

    return null;
};

export default FieldOptionDisplay;
