import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

type Setting = { orgId: string; name: string; value: string };

function getInitialState() {
    const item = localStorage.getItem('settings');
    return item ? JSON.parse(item) : {};
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        value: getInitialState(),
    },
    reducers: {
        setSettings: (state, action: PayloadAction<Setting>) => {
            const settings = state.value[action.payload.orgId] || {};
            settings[action.payload.name] = action.payload.value;

            state.value = { ...state.value, [action.payload.orgId]: settings };
            localStorage.setItem('settings', JSON.stringify(state.value));
        },
    },
});

export const { setSettings } = settingsSlice.actions;
export const getSettings = (state: RootState) => state.settingsSlice.value;

export default settingsSlice.reducer;
