import { useCurrentUser } from 'hooks/useCurrentUser';
import OpenDrawerButton from 'pages/zones/OpenDrawerButton';
import React, { useEffect, useState } from 'react';
import { TemplatedObject } from 'types';

import { isPersonViewer, map, parseHash } from 'utils';

import type { CPGNode } from 'components/canvas/cpg/types';
import { useHash } from 'hooks/hash';
import CirclePackingGraph from 'components/canvas/cpg/CirclePackingGraph';
import ObjectDrawer from 'components/drawers/ObjectDrawer';

import styles from './styles.module.scss';

import { useAppSelector } from 'store';
import { getZoneFilters } from 'features/zoneFilterSlice';

import NodeActions, { type NodeAction } from './NodeActions';
import ZoneChartLegend from './legend/ZoneChartLegend';

import { type ZoneChartData } from 'hooks/useData';
import NodeMenu, { MenuItem } from './NodeMenu';
import { showZone } from './utils';
import data from './data';

type ContextMenu = {
    node: CPGNode;
    x: number;
    y: number;
};

function onTooltipText(node: CPGNode | undefined) {
    return node?.obj.name;
}

const ZoneChart = (props: { zoneId?: string; objs: ZoneChartData }) => {
    const { zoneId, objs } = props;
    const [obj, setObj] = useState<TemplatedObject | undefined>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menu, setMenu] = useState<ContextMenu>();
    const { hash, removeHash } = useHash();
    const currentUser = useCurrentUser();

    const [action, setAction] = useState<NodeAction>();
    const zoneFilters = useAppSelector(getZoneFilters);

    let cpgData = data({
        org: objs['org'],
        zones: objs['zones'],
        zoneFilters: zoneId ? [] : zoneFilters,
        teams: objs['teams'],
        roles: objs['roles'],
        people: map(objs['people']),
    });
    if (zoneId) {
        cpgData = showZone(cpgData.root, cpgData.count, zoneId);
    }

    useEffect(() => {
        if (zoneId) {
            const ref = parseHash(hash);
            if (ref && ref.type === 'zone') {
                const zone = objs['zones'].find((z) => z.id === zoneId);
                if (zone) {
                    setObj(zone);
                    setDrawerOpen(true);
                    removeHash();
                }
            }
        }
    }, [hash, zoneId, objs, removeHash]);

    function onOpenDrawer() {
        const zone = objs['zones'].find((z) => z.id === zoneId);
        if (zone) {
            setObj(zone);
            setDrawerOpen(true);
        }
    }

    function onDoubleClick(node: CPGNode | undefined) {
        setObj(node?.obj);
        setDrawerOpen(true);
    }

    function onContextMenu(node: CPGNode | undefined, x: number, y: number) {
        if (node) {
            setMenu({ node, x, y });
        }
    }

    function onMenuClick(item: MenuItem) {
        if (item && item.key && menu) {
            setObj(menu.node.obj);
            setAction(item.key as NodeAction);
        }
        setMenu(undefined);
    }

    function onClose() {
        setDrawerOpen(false);
    }

    return (
        <>
            <div className={styles.container} onClick={() => setMenu(undefined)}>
                {cpgData.root ? (
                    <>
                        <CirclePackingGraph
                            {...cpgData}
                            onDoubleClick={onDoubleClick}
                            onContextMenu={!isPersonViewer(currentUser) ? onContextMenu : undefined}
                            onTooltipText={onTooltipText}
                            extraControls={zoneId ? <OpenDrawerButton onClick={onOpenDrawer} /> : null}
                        />
                        <ZoneChartLegend />
                    </>
                ) : null}
            </div>
            {obj && drawerOpen ? <ObjectDrawer objectId={obj.id} objectType={obj.type} onClose={onClose} /> : null}
            <NodeActions obj={obj} action={action} onClose={() => setAction(undefined)} />
            {menu ? <NodeMenu {...menu} onClick={onMenuClick} /> : null}
        </>
    );
};

export default ZoneChart;
