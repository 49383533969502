import { Space } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import ResetButton from './ResetButton';

import styles from './styles.module.scss';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

const CPGControls = (
    props: PropsWithChildren<{
        onZoomIn: () => void;
        onZoomOut: () => void;
        onReset: () => void;
        resetDisabled?: boolean;
        extraControls?: ReactNode;
    }>,
) => {
    return (
        <Space size={4} direction={'vertical'} className={styles.controls}>
            <ResetButton onClick={props.onReset} disabled={props.resetDisabled} />
            <ZoomInButton onClick={props.onZoomIn} />
            <ZoomOutButton onClick={props.onZoomOut} />
            {props.children}
        </Space>
    );
};

export default CPGControls;
