import React from 'react';
import { Avatar, Flex } from 'antd';

import type { Role } from 'types';
import { usePerson } from 'hooks/usePeople';

const RolePersonDetail = (props: { role: Role }) => {
    const person = usePerson(props.role.assignment_id);

    if (!props.role.assignment_id) {
        return <div className="no-role">This role is unfilled.</div>;
    }

    if (!person) {
        return null;
    }

    return (
        <div>
            <Flex className="drawer-avatar" vertical>
                {person.picture_large ? (
                    <Avatar className="avatar-media" src={person.picture_large} alt={person.name} />
                ) : null}
                <span className="avatar-name">{person.name}</span>
            </Flex>
        </div>
    );
};

export default RolePersonDetail;
