import { Select, SelectProps } from 'antd';
import { useFields } from 'hooks/fields';

const TemplateFieldSelect = (
    props: {
        excludedFieldIds?: string[];
    } & Omit<SelectProps, 'options'>,
) => {
    const { excludedFieldIds = [], ...selectProps } = props;
    const fields = useFields();

    const options = fields
        .filter((field) => !excludedFieldIds.includes(field.id))
        .map((field) => ({ value: field.id, label: field.name }));

    return <Select {...selectProps} options={options} />;
};

export default TemplateFieldSelect;
