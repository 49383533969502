import { useState } from 'react';
import { App } from 'antd';

import { PlatformError, Role } from 'types';

import { AppDispatch, useAppDispatch } from 'store';

import CreateEditSteps from 'components/steps/common/CreateEditSteps';
import PersonFormItem from 'components/forms/common/PersonFormItem';
import { updateRole } from 'features/roleSlice';

const AssignPersonToRoleSteps = (props: { role: Role; onSuccess: (role: Role) => void; onCancel: () => void }) => {
    const { message } = App.useApp();
    const [personId, setPersonId] = useState<string | null | undefined>(props.role.assignment_id);
    const dispatch: AppDispatch = useAppDispatch();

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setPersonId(undefined);
        }, 0);
    }

    async function onUpdate() {
        const role = { id: props.role.id } as Role;
        role.assignment_id = personId ? personId : null;

        const res = await dispatch(updateRole(role));
        if ((res as any).error) {
            message.error((res.payload as any as PlatformError).message);
            return;
        }
        props.onSuccess(res.payload as Role);

        const content = personId ? 'The role was assigned successfully.' : 'The role was unassigned.';
        message.success(content);
        return;
    }

    const steps = [
        {
            title: 'User',
            content: (
                <PersonFormItem
                    label="What user should have this role?"
                    personId={personId || undefined}
                    setPersonId={setPersonId}
                    allowClear={true}
                />
            ),
            valid: () => true,
        },
    ];

    return <CreateEditSteps steps={steps} onCreate={onUpdate} onCancel={onCancel} actionText={'Assign'} />;
};

export default AssignPersonToRoleSteps;
