import { useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';

import { CLEAR_ORG_ACTION, LOGOUT_ACTION, useAppDispatch } from 'store';

import HeaderUser from './HeaderUser';
import styles from './styles.module.scss';

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const items: MenuProps['items'] = [
        {
            label: 'Switch Organization',
            key: 'switch',
        },
        {
            label: 'Profile',
            key: 'profile',
        },
        {
            type: 'divider',
        },
        {
            label: 'Logout',
            key: 'logout',
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'logout':
                dispatch(LOGOUT_ACTION());
                navigate('/login');
                break;
            case 'switch':
                dispatch(CLEAR_ORG_ACTION());
                break;
            case 'profile':
                navigate('/profile');
                break;
        }
    };

    return (
        <Dropdown className={styles.profile} menu={{ items: items, onClick }} trigger={['click']}>
            <HeaderUser />
        </Dropdown>
    );
};

export default Profile;
