import React from 'react';
import { Form } from 'antd';

import type { MeetingElement } from 'types';
import EditableDnDTable, { DataType } from 'components/tables/editableDnD/EditableDnDTable';

const MeetingElementsFormItem = (props: {
    elements: MeetingElement[];
    setElements: (elements: MeetingElement[]) => void;
}) => {
    const columns = [
        { title: 'Title', dataIndex: 'title' },
        { title: 'Duration', dataIndex: 'duration' },
    ];

    const setValues = (values: DataType[]) => {
        const elements = values.map((value) => {
            return value as MeetingElement;
        });
        props.setElements(elements);
    };

    return (
        <Form.Item
            className={'form-control'}
            label={'What is the structure/flow for this meeting?'}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required
        >
            <EditableDnDTable uniqueOnly showHeader values={props.elements} setValues={setValues} columns={columns} />
        </Form.Item>
    );
};

export default MeetingElementsFormItem;
