import { useState } from 'react';
import { useMatches } from 'react-router-dom';
import { Divider, Layout } from 'antd';

import SiderLogo from './SiderLogo';

import { isPersonAdmin, isPersonSystem } from 'utils';

import styles from './styles.module.scss';
import TeamsMenu from './menus/TeamsMenu';
import ChartsMenu from './menus/ChartsMenu';
import ListsMenu from './menus/ListsMenu';
import { useCurrentUser } from 'hooks/useCurrentUser';
import TablesMenu from './menus/TablesMenu';
import SettingsMenu from './menus/SettingsMenu';

import { defaultSelectedKey } from './menus/utils';

const MainSider = () => {
    const currentUser = useCurrentUser();
    const matches = useMatches();
    const [selectedKey, setSelectedKey] = useState<string | undefined>(defaultSelectedKey(matches));
    const selectedKeys = selectedKey ? [selectedKey] : undefined;

    function onSelect({ key }: { key: string }) {
        setSelectedKey(key);
    }

    return (
        <Layout.Sider className={styles.sider} width={290}>
            <SiderLogo />
            <TeamsMenu selectedKeys={selectedKeys} onSelect={onSelect} />
            <Divider />
            <ChartsMenu selectedKeys={selectedKeys} onSelect={onSelect} />
            <Divider />
            <ListsMenu selectedKeys={selectedKeys} onSelect={onSelect} />
            {isPersonSystem(currentUser) ? (
                <>
                    <Divider />
                    <TablesMenu selectedKeys={selectedKeys} onSelect={onSelect} />
                </>
            ) : null}
            {isPersonAdmin(currentUser) ? (
                <>
                    <Divider />
                    <SettingsMenu selectedKeys={selectedKeys} onSelect={onSelect} />
                </>
            ) : null}
        </Layout.Sider>
    );
};

export default MainSider;
