import { Form } from 'antd';

import { OneToOneField, OneToManyField, ObjectType } from 'types';
import ObjectTypeSelect from 'components/forms/common/ObjectTypeSelect';

const RelationshipField = (props: {
    field: OneToOneField | OneToManyField;
    setField: (field: OneToOneField | OneToManyField) => void;
}) => {
    const allowed = props.field.config?.allowed ? props.field.config.allowed : [];

    function onChange(allowed: string[]) {
        props.setField({ ...props.field, config: { allowed: allowed as ObjectType[] } });
    }

    return (
        <div className="form-control">
            <Form.Item
                className="form-control"
                label={'Object types that may be referenced'}
                rules={[{ required: true, message: 'Select one or more values.' }]}
            >
                <ObjectTypeSelect mode="multiple" value={allowed} onChange={onChange} />
            </Form.Item>
        </div>
    );
};

export default RelationshipField;
