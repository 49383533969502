import React, { useState } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import type { Meeting, Team } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';
import CreateEditMeetingSteps from 'components/steps/meetings/CreateEditMeetingSteps';

const EditTeamMeetingButton = (props: { team: Team; meeting: Meeting; onConfirm?: (meeting: Meeting) => void }) => {
    const [openModal, setOpenModal] = useState(false);

    const onCancel = () => setOpenModal(false);
    const onCreate = (meeting: Meeting) => {
        props.onConfirm?.(meeting);
        setOpenModal(false);
    };

    return (
        <>
            <Button type={'link'} icon={<EditOutlined />} size={'small'} onClick={() => setOpenModal(true)} />
            <CreateEditModal title={'Update meeting'} open={openModal} onCancel={onCancel}>
                <CreateEditMeetingSteps
                    team={props.team}
                    meeting={props.meeting}
                    onSuccess={onCreate}
                    onCancel={onCancel}
                />
            </CreateEditModal>
        </>
    );
};

export default EditTeamMeetingButton;
