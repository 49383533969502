import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Objective, SliceStatus, Organization, ErrorDetail } from 'types';
import { CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState } from 'store';
import apiClient from 'api';

type ObjectivesState = {
    entities: Objective[];
    status: SliceStatus;
    error: string;
};

export const fetchObjectives = createAsyncThunk<Objective[]>('objectives/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    return await apiClient.get(`/org/${state.org.value.id}/objectives`);
});

export const deleteObjective = createAsyncThunk<
    Objective,
    string,
    {
        rejectValue: ErrorDetail;
    }
>('Objective/delete', async (ObjectiveId, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.delete<Objective>(`/org/${state.org.value.id}/objectives/${ObjectiveId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createObjective = createAsyncThunk<
    Objective,
    Objective,
    {
        rejectValue: ErrorDetail;
    }
>('objective/create', async (Objective, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.post(`/org/${state.org.value.id}/objectives`, Objective);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateObjective = Partial<Objective> & { id: string };
export const updateObjective = createAsyncThunk<
    Objective,
    UpdateObjective,
    {
        rejectValue: ErrorDetail;
    }
>('objective/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as { org: { value: Organization } };
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/objectives/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as ObjectivesState['status'],
    error: '',
};

// noinspection JSUnusedGlobalSymbols
const ObjectiveSlice = createSlice({
    name: 'objectives',
    initialState: INITIAL_STATE satisfies ObjectivesState as ObjectivesState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchObjectives.pending, (state: ObjectivesState) => {
                state.status = 'pending';
            })
            .addCase(fetchObjectives.fulfilled, (state: ObjectivesState, action: PayloadAction<Objective[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Objective[];
            })
            .addCase(fetchObjectives.rejected, (state: ObjectivesState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(
                createObjective.fulfilled,
                (state: ObjectivesState, action: PayloadAction<Objective | ErrorDetail>) => {
                    state.entities.push(action.payload as Objective);
                },
            )
            .addCase(deleteObjective.fulfilled, (state: ObjectivesState, action) => {
                state.entities = state.entities.filter((Objective: Objective) => Objective.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: ObjectivesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: ObjectivesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(
                updateObjective.fulfilled,
                (state: ObjectivesState, action: PayloadAction<Objective | ErrorDetail>) => {
                    const Objective = action.payload as Objective;
                    const index = state.entities.findIndex((r) => r.id === Objective.id);
                    state.entities[index] = Objective;
                },
            );
    },
});

export const selectObjectives = (state: RootState) => state.objectives.entities;
export const selectObjectiveStatus = (state: RootState) => state.objectives.status;

export default ObjectiveSlice.reducer;
