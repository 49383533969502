import React, { useEffect } from 'react';
import { Form, Select } from 'antd';

import { selectOptions } from 'utils';
import type { Template } from 'types';
import { useTemplates } from 'hooks/useTemplates';

const TemplateFormItem = (props: {
    objectType: Template['type'];
    templateId?: string;
    setTemplate: (templateId: string) => void;
}) => {
    const { templateId, setTemplate } = props;
    const templates = useTemplates(props.objectType);
    const options = selectOptions(templates);

    useEffect(() => {
        if (templates && templates.length === 1 && !templateId) {
            setTemplate(templates[0].id);
        }
    }, [templateId, setTemplate, templates]);

    return (
        <Form.Item
            className={'form-control'}
            label={'What template do you want to use?'}
            rules={[{ required: true }]}
            required={true}
        >
            <Select
                placeholder="Please select"
                value={props.templateId}
                onChange={props.setTemplate}
                options={options}
            />
        </Form.Item>
    );
};

export default TemplateFormItem;
