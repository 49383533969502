import { Flex, Typography } from 'antd';

function ErrorDisplay(props: { message: string }) {
    return (
        <Flex className={'w-100 min-h-100'} justify={'center'} vertical>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
                An unexpected error occurred :(
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
                Please contact Viggo support and reload the page
            </Typography.Paragraph>
            <Typography.Text style={{ textAlign: 'center' }}>{props.message}</Typography.Text>
        </Flex>
    );
}

export default ErrorDisplay;
