import { Select, SelectProps } from 'antd';

const ObjectTypeSelect = (props: Omit<SelectProps, 'options'>) => {
    return (
        <Select
            {...props}
            options={[
                { value: 'decision', label: 'Decision' },
                { value: 'objective', label: 'Objective' },
                { value: 'role', label: 'Role' },
                { value: 'service_agreement', label: 'Service Agreement' },
                { value: 'team', label: 'Team' },
                { value: 'zone', label: 'Zone' },
            ]}
        />
    );
};

export default ObjectTypeSelect;
