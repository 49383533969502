import { PropsWithChildren, ReactNode } from 'react';
import { Breadcrumb, BreadcrumbProps } from 'antd';
import styles from './styles.module.scss';

const SettingsCreateEditPage = (
    props: PropsWithChildren<{
        description?: ReactNode;
        breadcrumbs?: BreadcrumbProps['items'];
    }>,
) => {
    return (
        <div className={styles.page}>
            {props.breadcrumbs ? <Breadcrumb items={props.breadcrumbs} /> : null}
            <p>{props.description}</p>
            {props.children}
        </div>
    );
};

export default SettingsCreateEditPage;
