import { Select } from 'antd';
import { Person } from 'types';
import { selectOptions } from 'utils';

const PersonSelect = (props: { people: Person[]; personId: string; setPersonId: (personId: string) => void }) => {
    const options = selectOptions(props.people);
    return <Select value={props.personId} onChange={props.setPersonId} options={options} />;
};

export default PersonSelect;
