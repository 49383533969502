import React, { useState } from 'react';

import SettingsCreateButton from 'pages/settings/_common/SettingsCreateButton';
import { CreateEditTemplateCustomField } from 'types';

import CreateEditTemplateFieldModal from './CreateEditTemplateFieldModal';

const CreateTemplateFieldButton = (props: {
    onFinish?: (templateField: CreateEditTemplateCustomField) => void;
    excludedFieldIds?: string[];
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onCancel = () => setIsModalOpen(false);

    const onSave = async (templateField: CreateEditTemplateCustomField) => {
        props.onFinish?.(templateField);
        setIsModalOpen(false);
    };

    return (
        <>
            <SettingsCreateButton type={'dashed'} onClick={() => setIsModalOpen(true)}>
                Add Field
            </SettingsCreateButton>
            <CreateEditTemplateFieldModal
                open={isModalOpen}
                onSave={onSave}
                onCancel={onCancel}
                excludedFieldIds={props.excludedFieldIds}
            />
        </>
    );
};

export default CreateTemplateFieldButton;
