import { Card, type CardProps, Typography } from 'antd';
import { type LinkProps } from 'antd/lib/typography/Link';

const CardLink = (props: CardProps & { href?: LinkProps['href'] }) => {
    const { onClick, href, ...cardProps } = props;
    return onClick || href ? (
        <Typography.Link onClick={props.onClick} href={href}>
            <Card {...cardProps}>{props.children}</Card>
        </Typography.Link>
    ) : (
        <Card {...cardProps}>{props.children}</Card>
    );
};

export default CardLink;
