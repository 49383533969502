import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ErrorDetail, Organization } from 'types';
import { CLEAR_ORG_ACTION, RootState } from 'store';
import { getOrgFromSession, setOrgInSession } from 'utils';
import apiClient from 'api';

type UpdateOrganization = Partial<Organization> & { id: string };
export const updateOrganization = createAsyncThunk<
    Organization,
    UpdateOrganization,
    {
        rejectValue: ErrorDetail;
    }
>('organization/update', async (data, thunkAPI) => {
    try {
        return await apiClient.patch(`/org/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const orgSlice = createSlice({
    name: 'org',
    initialState: {
        value: getOrgFromSession() as Organization | null,
    },
    reducers: {
        setOrg: (state, action: PayloadAction<Organization | null>) => {
            state.value = action.payload;
            if (state.value) {
                /* Orgs and people no longer have a user_type */
                state.value.type = 'organization';
            }
            setOrgInSession(state.value);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(CLEAR_ORG_ACTION, (state) => {
                state.value = null;
                setOrgInSession(state.value);
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.value = action.payload as Organization;
                setOrgInSession(state.value);
            });
    },
});

export const { setOrg } = orgSlice.actions;
export const getOrg = (state: RootState) => state.org.value;

export default orgSlice.reducer;
