import React, { useState } from 'react';

import SettingsCreateButton from 'pages/settings/_common/SettingsCreateButton';
import { Field } from 'types';

import { getErrorMessage } from 'utils';
import { useOrg } from 'hooks/useOrg';
import { createField } from 'services/fields';

import EditFieldModal from '../edit/EditFieldModal';
import { App } from 'antd';

const CreateFieldButton = (props: { onFinish?: (field: Field) => void }) => {
    const org = useOrg();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { message } = App.useApp();

    if (!org) return null;

    const onCancel = () => setIsModalOpen(false);

    const onSave = async (field: Field) => {
        try {
            const newField = await createField(org, field);
            setIsModalOpen(false);
            props.onFinish?.(newField);
            message.success('Field created.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    };

    return (
        <>
            <SettingsCreateButton onClick={() => setIsModalOpen(true)}>Create Field</SettingsCreateButton>
            <EditFieldModal title={'Create Field'} open={isModalOpen} onCancel={onCancel} onSave={onSave} />
        </>
    );
};

export default CreateFieldButton;
