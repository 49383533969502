import type { MenuProps } from 'antd';

import { Team, Zone, Role, ObjectType, BaseObject } from 'types';
import CreateRoleSteps from 'components/steps/CreateRoleSteps';
import CreateEditModal from 'components/steps/common/CreateEditModal';
import CreateZoneSteps from 'components/steps/CreateZoneSteps';
import CreateTeamSteps from 'components/steps/CreateTeamSteps';
import AssignPersonToRoleSteps from 'components/steps/AssignPersonToRoleSteps';

export type NodeAction = 'add-role' | 'add-zone' | 'add-team' | 'assign-user-to-role';

export const zoneMenuItems: MenuProps['items'] = [
    {
        label: 'Add Zone',
        key: 'add-zone',
    },
    {
        label: 'Add Team',
        key: 'add-team',
    },
];

export const teamMenuItems: MenuProps['items'] = [
    {
        label: 'Add Role',
        key: 'add-role',
    },
];

export const roleMenuItems: MenuProps['items'] = [
    {
        label: 'Assign Role',
        key: 'assign-user-to-role',
    },
];

export function getByType(objectType: ObjectType) {
    switch (objectType) {
        case 'zone':
            return zoneMenuItems;
        case 'team':
            return teamMenuItems;
        case 'role':
            return roleMenuItems;
    }
    return undefined;
}

const NodeActions = (props: { action: NodeAction | undefined; obj: BaseObject | undefined; onClose: () => void }) => {
    if (!props.obj) return null;
    return props.obj ? (
        <div>
            <CreateEditModal
                title={`Add role to ${props.obj.name}`}
                open={props.action === 'add-role'}
                onCancel={props.onClose}
            >
                <CreateRoleSteps
                    team={props.obj as Team | undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Add zone to ${props.obj.name}`}
                open={props.action === 'add-zone'}
                onCancel={props.onClose}
            >
                <CreateZoneSteps
                    zone={props.obj as Zone | undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Add team to ${props.obj.name}`}
                open={props.action === 'add-team'}
                onCancel={props.onClose}
            >
                <CreateTeamSteps
                    zone={props.obj as Zone | undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Assign User to ${props.obj.name}`}
                open={props.action === 'assign-user-to-role'}
                onCancel={props.onClose}
                width={600}
            >
                <AssignPersonToRoleSteps role={props.obj as Role} onSuccess={props.onClose} onCancel={props.onClose} />
            </CreateEditModal>
        </div>
    ) : null;
};

NodeActions.getByType = getByType;

export default NodeActions;
