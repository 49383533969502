import React from 'react';
import { Modal } from 'antd';

import styles from './styles.module.scss';

const CreateEditModal = (props: {
    open: boolean;
    title: string;
    onCancel: () => void;
    children: React.ReactNode;
    width?: string | number;
}) => {
    const width = props.width ? props.width : 800;
    /* Destroy steps on close to reset the form content. */
    return (
        <Modal
            className={styles.modal}
            title={props.title}
            open={props.open}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
            maskClosable={true}
            width={width}
        >
            {props.children}
        </Modal>
    );
};

export default CreateEditModal;
