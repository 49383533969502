import { Row, Col } from 'antd';
import { Meeting, NonEmptyArray, Team } from 'types';
import { useTeamMeetings } from 'hooks/meetings';
import TeamMeetingsCard from './cards/meetings/TeamMeetingsCard';

const TeamMeetingsRow = (props: { team: Team }) => {
    const meetings = useTeamMeetings();
    if (!meetings || meetings.length === 0) return null;

    return (
        <Row gutter={16}>
            <Col span={24}>
                <TeamMeetingsCard team={props.team} meetings={meetings as NonEmptyArray<Meeting>} />
            </Col>
        </Row>
    );
};

export default TeamMeetingsRow;
