import React from 'react';
import { Collapse } from 'antd';

import { isPersonSystem } from 'utils';
import { useCurrentUser } from 'hooks/useCurrentUser';

const Object = (props: { label?: string; object: any; style?: React.CSSProperties }) => {
    const currentUser = useCurrentUser();
    if (!isPersonSystem(currentUser)) return null;

    const text = <pre>{JSON.stringify(props.object, null, 4)}</pre>;

    const items = [
        {
            key: 'object',
            label: props.label ? props.label : 'Object',
            children: text,
        },
    ];

    return <Collapse style={props.style} items={items} bordered={false} />;
};

export default Object;
