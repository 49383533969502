import { Form, Select } from 'antd';
import { ObjectRef, OneToOneField, TemplateField } from 'types';
import { buildObjectOptions, fieldLabel, getField } from 'utils';
import { useObjects } from 'hooks/useObjects';

const OneToOneFormItem = (props: {
    templateField: TemplateField;
    objectRef: ObjectRef | undefined;
    setObjectRef: (ref: ObjectRef) => void;
}) => {
    const field = getField(props.templateField) as OneToOneField;
    const objs = useObjects(field.config.allowed);

    const options = buildObjectOptions(objs);
    const value = props.objectRef ? JSON.stringify({ id: props.objectRef.id, type: props.objectRef.type }) : undefined;

    return (
        <Form.Item
            className={'form-control'}
            label={fieldLabel(field.name)}
            rules={[{ required: props.templateField.required, message: 'Select a value.' }]}
            name={field.name}
            initialValue={value}
        >
            <Select
                options={options}
                onChange={(value) => props.setObjectRef(value ? JSON.parse(value) : null)}
                allowClear={!props.templateField.required}
                showSearch={true}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Form.Item>
    );
};

export default OneToOneFormItem;
