import { useNavigate } from 'react-router-dom';
import { App, Button, Flex, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { getDispatchError, hasDispatchError, isPersonAdmin } from 'utils';
import { AppDispatch, useAppDispatch } from 'store';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { deleteTemplate } from 'features/templateSlice';

const DeleteTemplateButton = (props: { templateId: string }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const { modal, message } = App.useApp();

    const isAdmin = isPersonAdmin(useCurrentUser());
    const {
        token: { colorError },
    } = theme.useToken();
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deleteTemplate(props.templateId));
        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        message.success('Your template was successfully deleted.');
        navigate('/settings#templates', { replace: true });
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    modal.confirm({
                        title: 'Confirm Template Deletion',
                        content:
                            'Are you sure you want to permanently delete this template?  This operation cannot be undone.',
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <CancelBtn />
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Template',
                        okButtonProps: { type: 'default', danger: true },
                        icon: <CloseCircleOutlined style={{ color: colorError }} />,
                        onOk: onConfirm,
                    });
                }}
            >
                Delete Template
            </Button>
        </Flex>
    );
};

export default DeleteTemplateButton;
