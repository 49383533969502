import { type CSSProperties, type MouseEvent, useEffect, useRef, useState, forwardRef } from 'react';

import { getNodeFromEvent } from 'components/canvas/utils';

import { FDGLink, FDGNode } from './types';
import simulate from './simulate';
import draw from './draw';
import FDGTooltip from './FDGTooltip';

const WIDTH = 928;
const HEIGHT = 600;

interface FDGProps {
    data: { nodes: FDGNode[]; links: FDGLink[] };
    radius?: number;
    onClick?: (node: FDGNode) => void;
    onTooltipText?: (node: FDGNode | undefined) => string;
}

const ForceDirectedGraph = forwardRef<HTMLDivElement, FDGProps>((props, ref) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const shadowCanvas = useRef<HTMLCanvasElement>(null);

    const [colorMap, setColorMap] = useState<Map<string, FDGNode>>();
    const [hoverNode, setHoverNode] = useState<FDGNode>();

    const context = canvas.current ? canvas.current.getContext('2d') : undefined;
    const shadowContext = shadowCanvas.current
        ? shadowCanvas.current.getContext('2d', { willReadFrequently: true })
        : undefined;

    useEffect(() => {
        if (!context || !shadowContext) {
            return;
        }

        const { nodes, links } = simulate({
            data: props.data,
            width: WIDTH,
            height: HEIGHT,
            radius: props.radius,
        });

        const colorMap = draw({
            width: WIDTH,
            height: HEIGHT,
            context,
            shadowContext,
            nodes,
            links,
            radius: props.radius,
        });
        setColorMap(colorMap);
    }, [context, shadowContext, setColorMap, props.data, props.radius]);

    function onClick(event: MouseEvent) {
        if (colorMap && shadowContext) {
            const node = getNodeFromEvent<FDGNode>(event, shadowContext, colorMap);
            if (node) {
                props.onClick?.(node);
            }
        }
    }

    function onMouseMove(event: MouseEvent) {
        if (colorMap && shadowContext) {
            const node = getNodeFromEvent(event, shadowContext, colorMap);
            if (node?.id !== hoverNode?.id) {
                setHoverNode(node);
            }
        }
    }

    /* Canvas elements are inline by default which causes a gap and messes up the color calcs. */
    const style: CSSProperties = {
        display: 'block',
        boxSizing: 'border-box',
        width: '100%',
        cursor: hoverNode ? 'pointer' : 'default',
        padding: 0,
        margin: 0,
        border: 0,
    };

    return (
        <div ref={ref} style={{ position: 'relative' }}>
            <canvas
                style={style}
                width={WIDTH}
                height={HEIGHT}
                ref={canvas}
                onMouseMove={onMouseMove}
                onClick={onClick}
            ></canvas>
            <canvas
                style={{ ...style, visibility: 'hidden' }}
                width={WIDTH}
                height={HEIGHT}
                ref={shadowCanvas}
            ></canvas>
            {props.onTooltipText && (
                <FDGTooltip canvas={canvas.current} node={hoverNode} onTooltipText={props.onTooltipText}></FDGTooltip>
            )}
        </div>
    );
});

export default ForceDirectedGraph;
