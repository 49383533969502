import { useState } from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import { App } from 'antd';

import { Team, Zone, PersonRef, ErrorDetail } from 'types';
import { AppDispatch, useAppDispatch } from 'store';
import { getDispatchError, hasDispatchError } from 'utils';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { createTeam } from 'features/teamSlice';
import GeneralSteps from 'components/steps/common/GeneralSteps';

const CreateTeamSteps = (props: { zone?: Zone; onSuccess: (team: Team) => void; onCancel: () => void }) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [team, setTeam] = useState(() => {
        const team: Team = { type: 'team' } as Team;
        if (props.zone) {
            team.zone = { type: 'zone', id: props.zone.id };
        }
        return team;
    });

    const [loading, setLoading] = useState(false);

    async function onCreate() {
        setLoading(true);
        const newTeam = {
            ...team,
            owners: [{ id: currentUser?.id, type: 'person' } as PersonRef],
        };
        if (props.zone) {
            newTeam.zone = { id: props.zone.id, type: 'zone' };
        }
        const res = (await dispatch(createTeam(newTeam as Team) as any)) as PayloadAction<
            Team | ErrorDetail,
            any,
            any,
            string
        >;
        setLoading(false);

        if (hasDispatchError(res)) {
            message.error(getDispatchError(res));
            return;
        }
        props.onSuccess(res.payload as Team);
        message.success('Your team was created successfully.');
    }

    /* Set zone */
    const skip = props.zone ? ['zone'] : undefined;

    return (
        <GeneralSteps
            obj={team}
            setObj={setTeam}
            onFinish={onCreate}
            onCancel={props.onCancel}
            loading={loading}
            skip={skip}
        />
    );
};

export default CreateTeamSteps;
