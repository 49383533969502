import PersonFormItem from 'components/forms/common/PersonFormItem';
import type { Role, TemplateNativeField } from 'types';

const handleRole = (role: Role, templateField: TemplateNativeField, onChange: (value: any) => void) => {
    const setPersonId = (value: string | undefined) => {
        onChange(value || null);
    };

    switch (templateField.native_field.name) {
        case 'assignment_id':
            return (
                <PersonFormItem
                    allowClear
                    label={'Assigment'}
                    personId={role.assignment_id || undefined}
                    setPersonId={setPersonId}
                />
            );
        case 'accountable_for':
            return null; // Prevent editing this.
    }
    return undefined;
};

export default handleRole;
