import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { SettingOutlined, ToolOutlined } from '@ant-design/icons';

import { isPersonSystem } from 'utils';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type { MenuItem, SiderMenuProps } from './types';

const SettingsMenu = (props: SiderMenuProps) => {
    const currentUser = useCurrentUser();

    const menuItems = [
        {
            key: 'settings',
            label: <Link to="/settings">Settings</Link>,
            icon: <SettingOutlined />,
        },
    ] as MenuItem[];

    if (isPersonSystem(currentUser)) {
        menuItems.push({
            key: 'admin',
            label: <Link to="/admin">Admin (System Only)</Link>,
            icon: <ToolOutlined />,
        });
    }

    return <Menu mode="inline" items={menuItems} selectedKeys={props.selectedKeys} onSelect={props.onSelect} />;
};

export default SettingsMenu;
