import React from 'react';

import { Table } from 'antd';
import type { TableColumnsType } from 'antd';

import styles from './styles.module.scss';
import type { Field, Keyed } from 'types';
import { asDataSource, nameSort } from 'utils';

import { useFieldsState } from 'hooks/fields';
import { useOrg } from 'hooks/useOrg';

import FieldOptionDisplay from './FieldOptionDisplay';
import CreateFieldButton from './CreateFieldButton';
import DeleteFieldButton from './DeleteFieldButton';
import EditFieldButton from './EditFieldButton';

const FieldsTable = () => {
    const org = useOrg();

    const [fields, setFields] = useFieldsState();

    if (!org) return null;

    const columns: TableColumnsType<Keyed<Field>> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text) => <span className={'text-nowrap'}>{text}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (text, obj) => {
                return (
                    <>
                        <div className={`t-icon ${text}`}>{text}</div>
                        <FieldOptionDisplay field={obj} />
                    </>
                );
            },
        },
        {
            dataIndex: 'actions',
            render: (_, field) => (
                <span className={'text-nowrap actions'}>
                    <EditFieldButton field={field} onFinish={onEdit} />
                    <DeleteFieldButton fieldId={field.id} onDelete={onDelete} />
                </span>
            ),
        },
    ];

    const onCreate = async (field: Field) => {
        setFields((fields) => {
            const newFields = fields ? [...fields, field] : [field];
            return newFields.sort(nameSort);
        });
    };

    const onEdit = async (field: Field) => {
        setFields((fields) => {
            const newFields = fields ? fields.filter((f) => f.id !== field.id) : [];
            newFields.push(field);
            return newFields.sort(nameSort);
        });
    };

    const onDelete = async (field: Field) => {
        setFields((fields) => {
            return fields ? fields?.filter((f) => f.id !== field.id) : [];
        });
    };

    return (
        <>
            <Table<Keyed<Field>>
                className={styles.table}
                rowKey="key"
                columns={columns}
                dataSource={asDataSource<Field>(fields)}
                pagination={false}
                rowHoverable={false}
            />
            <CreateFieldButton onFinish={onCreate} />
        </>
    );
};

export default FieldsTable;
