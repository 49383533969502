import { useRouteError } from 'react-router-dom';
import { isString } from 'utils';
import ErrorDisplay from './ErrorDisplay';

function ErrorBoundary() {
    const error = useRouteError() as Response;
    return <ErrorDisplay message={isString(error) ? error.toString() : JSON.stringify(error)} />;
}

export default ErrorBoundary;
