import { Person } from 'types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const UserImage = (props: { user: Person }) => {
    const imageSize = 32;

    if (!props.user.picture_small) {
        return <Avatar size={imageSize} icon={<UserOutlined />} />;
    }

    return <Avatar size={imageSize} src={props.user.picture_medium} />;
};

export default UserImage;
