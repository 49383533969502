import { useParams } from 'react-router-dom';
import { useTemplates } from 'hooks/useTemplates';

import { map } from 'utils';
import CreateEditTemplatePage from './CreateEditTemplatePage';

const EditTemplatePage = () => {
    let { templateId } = useParams();
    const templates = map(useTemplates());
    const template = templateId ? templates[templateId] : undefined;
    if (!template) return null;

    return <CreateEditTemplatePage template={template} />;
};

export default EditTemplatePage;
