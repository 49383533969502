import { fetchObjectives, selectObjectives, selectObjectiveStatus } from 'features/objectiveSlice';
import { useEffect } from 'react';

import type { BaseObject, ObjectType } from 'types';
import { useAppDispatch, useAppSelector } from 'store';

import { nameSort } from 'utils';
import { fetchPeople, selectPeople, selectPeopleStatus } from 'features/personSlice';
import { fetchZones, selectZones, selectZoneStatus } from 'features/zoneSlice';
import { fetchTeams, selectTeams, selectTeamStatus } from 'features/teamSlice';
import { fetchRoles, selectRoles, selectRoleStatus } from 'features/roleSlice';
import { fetchDecisions, selectDecisions, selectDecisionStatus } from 'features/decisionSlice';

// Yuck
export function useObjects(allowed: ObjectType[]) {
    const dispatch = useAppDispatch();

    const people = useAppSelector(selectPeople) || [];
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const zones = useAppSelector(selectZones);
    const zoneStatus = useAppSelector(selectZoneStatus);

    const teams = useAppSelector(selectTeams);
    const teamStatus = useAppSelector(selectTeamStatus);

    const roles = useAppSelector(selectRoles);
    const roleStatus = useAppSelector(selectRoleStatus);

    const decisions = useAppSelector(selectDecisions);
    const decisionStatus = useAppSelector(selectDecisionStatus);

    const objectives = useAppSelector(selectObjectives);
    const objectiveStatus = useAppSelector(selectObjectiveStatus);

    useEffect(() => {
        if (peopleStatus === 'idle' && allowed.includes('person')) {
            dispatch(fetchPeople());
        }
    }, [peopleStatus, dispatch, allowed]);

    useEffect(() => {
        if (zoneStatus === 'idle' && allowed.includes('zone')) {
            dispatch(fetchZones() as any);
        }
    }, [zoneStatus, dispatch, allowed]);

    useEffect(() => {
        if (teamStatus === 'idle' && allowed.includes('team')) {
            dispatch(fetchTeams() as any);
        }
    }, [teamStatus, dispatch, allowed]);

    useEffect(() => {
        if (roleStatus === 'idle' && allowed.includes('role')) {
            dispatch(fetchRoles());
        }
    }, [roleStatus, dispatch, allowed]);

    useEffect(() => {
        if (decisionStatus === 'idle' && allowed.includes('decision')) {
            dispatch(fetchDecisions());
        }
    }, [decisionStatus, dispatch, allowed]);

    useEffect(() => {
        if (objectiveStatus === 'idle' && allowed.includes('objective')) {
            dispatch(fetchObjectives());
        }
    }, [objectiveStatus, dispatch, allowed]);

    return {
        person: allowed.includes('person') ? [...people].sort(nameSort) : undefined,
        zone: allowed.includes('zone') ? [...zones].sort(nameSort) : undefined,
        team: allowed.includes('team') ? [...teams].sort(nameSort) : undefined,
        role: allowed.includes('role') ? [...roles].sort(nameSort) : undefined,
        decision: allowed.includes('decision') ? [...decisions].sort(nameSort) : undefined,
        objective: allowed.includes('objective') ? [...objectives].sort(nameSort) : undefined,
    } as { [key: string]: BaseObject[] };
}

export function useObject<T extends BaseObject>(type: ObjectType, id: string) {
    const objs = useObjects([type]);
    return objs[type] ? objs[type].find<T>((x: BaseObject): x is T => x.id === id) : undefined;
}
