import { Button, Space, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { isPersonViewer } from 'utils';
import styles from './styles.module.scss';

const ObjectDrawerEdit = (props: { name: string; edit: boolean; setEdit: (value: boolean) => void }) => {
    return (
        <Tooltip title={`Edit ${props.name}`}>
            <Button
                shape="circle"
                icon={props.edit ? <CloseOutlined /> : <EditOutlined />}
                onClick={() => props.setEdit(!props.edit)}
            />
        </Tooltip>
    );
};

const ObjectDrawerClose = (props: { onClose: () => void }) => {
    return (
        <Tooltip title={'Close'}>
            <Button shape="circle" icon={<CloseOutlined />} onClick={props.onClose} />
        </Tooltip>
    );
};

const ObjectDrawerButtons = (props: {
    name: string;
    edit: boolean;
    setEdit: (value: boolean) => void;
    onClose: () => void;
}) => {
    const currentUser = useCurrentUser();

    return (
        <Space className={styles.buttons}>
            {!isPersonViewer(currentUser) && (
                <ObjectDrawerEdit name={props.name} edit={props.edit} setEdit={props.setEdit} />
            )}
            {!props.edit && <ObjectDrawerClose onClose={props.onClose} />}
        </Space>
    );
};

export default ObjectDrawerButtons;
