import { useCurrentUser } from 'hooks/useCurrentUser';
import { Team } from 'types';
import CreateRoleSteps from 'components/steps/CreateRoleSteps';
import CreateButton from 'components/steps/common/CreateButton';
import { isPersonViewer } from 'utils';

const AddRoleButton = (props: { team: Team }) => {
    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    return (
        <CreateButton title={'Add Role'}>
            {(onSuccess, onCancel) => <CreateRoleSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />}
        </CreateButton>
    );
};

export default AddRoleButton;
