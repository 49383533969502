import { Card, type CardProps, Flex, Typography } from 'antd';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { isPersonViewer } from 'utils';

import styles from './styles.module.scss';

const DangerZoneCard = (props: CardProps) => {
    const currentUser = useCurrentUser();
    if (isPersonViewer(currentUser)) return null;

    return (
        <Card size="small" className={`${styles.card} no-head-border`}>
            <Card.Meta title={<Typography.Text type={'danger'}>Danger Zone</Typography.Text>} />
            <Flex justify={'center'}>{props.children}</Flex>
        </Card>
    );
};

export default DangerZoneCard;
