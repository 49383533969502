import { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { base64Encode } from 'utils';

import 'cropperjs/dist/cropper.css';

const AvatarCrop = (props: { bytes: ArrayBuffer; onCrop: (data: string) => void }) => {
    const [box, setBox] = useState<Cropper.CropBoxData>();
    const cropperRef = useRef<ReactCropperElement>(null);

    const onCropMove = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setBox(cropper.getCropBoxData());
        }
    };

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            props.onCrop(cropper.getCroppedCanvas().toDataURL('image/jpeg'));
        }
    };

    const src = 'data:image/png;base64,' + base64Encode(props.bytes);
    return (
        <>
            <Cropper
                src={src}
                style={{ height: 400, width: '100%' }}
                // Cropper.js options
                aspectRatio={1}
                guides={true}
                crop={onCrop}
                cropmove={onCropMove}
                autoCropArea={1}
                ref={cropperRef}
            />
            {!!box && (
                <small>
                    ({Math.round(box.left)},{Math.round(box.top)}) ({Math.round(box.width)}, {Math.round(box.height)})
                </small>
            )}
        </>
    );
};

export default AvatarCrop;
