import { useNavigate } from 'react-router-dom';
import { App, Button, Flex, Modal, theme } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { Decision, PlatformDispatch, PlatformError } from 'types';
import { isDefined, isPersonAdmin, isUndefined } from 'utils';
import { useCurrentUser } from 'hooks/useCurrentUser';

import { AppDispatch, useAppDispatch } from 'store';
import { deleteDecision } from 'features/decisionSlice';

const DeleteDecisionButton = (props: {
    decisionId: string;
    onDelete?: (decision: Decision) => void;
    navigate?: string | boolean;
}) => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const {
        token: { colorError },
    } = theme.useToken();
    const navigate = useNavigate();

    const isAdmin = isPersonAdmin(useCurrentUser());
    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = (await dispatch(deleteDecision(props.decisionId) as any)) as PlatformDispatch<Decision>;
        if (res.error) {
            message.error((res.payload as PlatformError).message);
            return;
        }

        props.onDelete?.(res.payload as Decision);
        if (isDefined(props.navigate) && props.navigate) {
            navigate(props.navigate as string, { replace: true });
        } else if (isUndefined(props.navigate)) {
            navigate('/decisions', { replace: true });
        }
        message.success('Your decision was successfully deleted.');
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: 'Confirm Decision Deletion',
                        content:
                            'Are you sure you want to permanently delete this decision?  This operation cannot be undone.',
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <CancelBtn />
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Decision',
                        okButtonProps: { type: 'default', danger: true },
                        icon: <CloseCircleOutlined style={{ color: colorError }} />,
                        onOk: onConfirm,
                    });
                }}
            >
                Delete Decision
            </Button>
        </Flex>
    );
};

export default DeleteDecisionButton;
