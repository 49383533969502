import { Button, Tooltip } from 'antd';
import { CompressOutlined } from '@ant-design/icons';

const ResetButton = (props: { onClick: () => void; disabled?: boolean }) => {
    return (
        <Tooltip placement={'left'} title={'Reset'}>
            <Button size={'small'} disabled={props.disabled} icon={<CompressOutlined />} onClick={props.onClick} />
        </Tooltip>
    );
};

export default ResetButton;
