import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import type { Template } from 'types';
import { nameSort } from 'utils';
import { fetchTemplates, selectTemplates, selectTemplateStatus } from 'features/templateSlice';

export function useTemplates(objectType?: Template['type']): Template[] {
    const dispatch = useAppDispatch();
    let templates = useAppSelector(selectTemplates) || [];
    const templateStatus = useAppSelector(selectTemplateStatus);

    useEffect(() => {
        if (templateStatus === 'idle') {
            dispatch(fetchTemplates());
        }
    }, [templateStatus, dispatch]);

    if (objectType) {
        templates = templates.filter((template) => template.type === objectType);
    }

    return [...templates].sort(nameSort);
}

export function useTemplate(id: string | undefined, objectType: string | undefined): Template | undefined {
    const templates = useTemplates();
    return id && objectType
        ? templates.find((template) => template.id === id && template.type === objectType)
        : undefined;
}

export function useTemplateByName(name: string, objectType: string) {
    return useTemplates().find((template) => {
        return template.type === objectType && template.name.toLowerCase() === name.toLowerCase();
    });
}
