import { BaseObject, MultiSelectField } from 'types';
import { fieldLabel } from 'utils';
import DrawerDescription from './DrawerDescription';

const MultiSelectFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any };
    field: MultiSelectField;
}) => {
    const values: string[] | undefined = props.obj[props.field.name];
    if (!values) {
        return null;
    }

    return (
        <DrawerDescription title={fieldLabel(props.field.name)}>
            {values.map((value, i) => (
                <div key={`${props.field.name}-${i}`}>{value}</div>
            ))}
        </DrawerDescription>
    );
};

export default MultiSelectFieldDrawerDescription;
