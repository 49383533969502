import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { asDataSource } from 'utils';
import { useObjectives } from 'hooks/useObjectives';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, { id, team }) => {
            return <Link to={`/teams/${team.id}#objective:${id}`}>{text}</Link>;
        },
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    /*
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
     */
];

const ObjectivesListPage = () => {
    const dataSource = asDataSource(useObjectives());
    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
};

export default ObjectivesListPage;
