import { Field } from 'types';
import CreateEditModal from 'components/steps/common/CreateEditModal';

import FieldForm from './FieldForm';

const EditFieldModal = (props: {
    title: string;
    field?: Field;
    open: boolean;
    onSave: (field: Field) => void;
    onCancel?: () => void;
}) => {
    return (
        <CreateEditModal title={props.title} open={props.open} onCancel={() => props.onCancel?.()} width={500}>
            <FieldForm field={props.field} onFinish={props.onSave} onCancel={() => props.onCancel?.()} />
        </CreateEditModal>
    );
};

export default EditFieldModal;
