import apiClient from 'api';
import PeopleInfoCard from 'components/cards/PeopleInfoCard';
import React from 'react';
import { Card, Typography, Space, App } from 'antd';

import { getErrorMessage } from 'utils';
import type { BaseObject, ObjectPictures, Person } from 'types';
import { AppDispatch, useAppDispatch } from 'store';
import { useCurrentUser } from 'hooks/useCurrentUser';
import EditableAvatar from 'components/avatar/EditableAvatar';
import { setCurrentUser } from 'features/currentUserSlice';
import DefaultHomePageCard from './DefaultHomePageCard';

const ProfilePage = () => {
    const { message } = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    if (!currentUser) return null;

    async function onAvatarChange(obj: BaseObject & ObjectPictures) {
        try {
            const person = await apiClient.patch<Person>('/auth/me', obj);
            dispatch(setCurrentUser(person));
        } catch (e) {
            message.error(getErrorMessage(e));
        }
        return true;
    }

    return (
        <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
            <Card>
                <Space align={'center'} size={'large'}>
                    <EditableAvatar size={64} obj={currentUser} updateObject={onAvatarChange} />
                    <Space direction={'vertical'}>
                        <Typography.Title level={2} style={{ margin: 0 }}>
                            {currentUser.name}
                        </Typography.Title>
                        {currentUser.title ? <Typography.Text>{currentUser.title}</Typography.Text> : null}
                    </Space>
                </Space>
            </Card>
            <PeopleInfoCard person={currentUser} />
            <DefaultHomePageCard />
        </Space>
    );
};

export default ProfilePage;
