import { UniqueIdentifier } from '@dnd-kit/core';
import { getErrorMessage, isDebug } from 'utils';
import { AgendaItem, Meeting } from 'types';

const KEY = 'teamMeeting';

export function getDefaultTeamMeetingId(teamId: string): string | undefined {
    const configText = localStorage.getItem(KEY);
    if (configText) {
        try {
            const config = JSON.parse(configText);
            return config[teamId] as string | undefined;
        } catch (e) {
            console.log(`Invalid ${KEY} in localStorage: ${getErrorMessage(e)}`);
        }
    }
}

export function setDefaultTeamMeeting(teamId: string, meetingId: string) {
    let config: Record<string, string> = {};

    const configText = localStorage.getItem(KEY);
    if (configText) {
        try {
            config = JSON.parse(configText);
        } catch (e) {
            console.log(`Invalid ${KEY} in localStorage: ${getErrorMessage(e)}`);
        }
    }
    config[teamId] = meetingId;
    localStorage.setItem(KEY, JSON.stringify(config));
}

export function nextOrder(agendaItems: AgendaItem[]) {
    let n = 0;
    for (const agendaItem of agendaItems) {
        n = Math.max(agendaItem.n, n);
    }
    return n + 1;
}

function findBeforeIndex(agendaItems: AgendaItem[], index: number, activeId: UniqueIdentifier) {
    while (index >= 0) {
        const agendaItem = agendaItems[index];
        if (agendaItem.id !== activeId) {
            return index;
        }
        index--;
    }
    return -1;
}

function findAfterIndex(agendaItems: AgendaItem[], index: number, activeId: UniqueIdentifier) {
    while (index < agendaItems.length) {
        const agendaItem = agendaItems[index];
        if (agendaItem.id !== activeId) {
            return index;
        }
        index++;
    }
    return -1;
}

function findGapAfter(agendaItems: AgendaItem[], index: number, activeId: UniqueIdentifier): [number, number] {
    const beforeIndex = findBeforeIndex(agendaItems, index, activeId);
    const afterIndex = findAfterIndex(agendaItems, index + 1, activeId);

    return [beforeIndex, afterIndex];
}

function findGapBefore(agendaItems: AgendaItem[], index: number, activeId: UniqueIdentifier): [number, number] {
    const beforeIndex = findBeforeIndex(agendaItems, index - 1, activeId);
    const afterIndex = findAfterIndex(agendaItems, index, activeId);

    return [beforeIndex, afterIndex];
}

/* Try to find an integer in the range [a,b] (exclusive) */
function between(a: number, b: number) {
    let n = (a + b) / 2;
    if (Math.ceil(n) < b) {
        return Math.ceil(n);
    }
    if (Math.floor(n) > a) {
        return Math.floor(n);
    }
    return n;
}

export function insertOrder(
    agendaItems: AgendaItem[],
    overItem: AgendaItem,
    activeId: UniqueIdentifier,
    insertBefore: boolean,
) {
    const debug = isDebug('order');
    const label = insertBefore ? 'before' : 'after';

    let index = agendaItems.findIndex((item) => item.id === overItem.id);
    const [beforeIndex, afterIndex] = insertBefore
        ? findGapBefore(agendaItems, index, activeId)
        : findGapAfter(agendaItems, index, activeId);

    if (beforeIndex === -1 && afterIndex === -1) {
        if (debug) {
            console.log(`${label}[${index}]: [-1, -1] -> 0`);
        }
        return 0;
    }

    if (beforeIndex === -1) {
        const n = Math.min(Math.floor(agendaItems[afterIndex].n) - 1, 0);
        if (debug) {
            console.log(`${label}[${index}]: [-1, ${afterIndex}:${agendaItems[afterIndex].n}] -> ${n}`);
        }
        return n;
    }

    if (afterIndex === -1) {
        const n = Math.floor(agendaItems[beforeIndex].n + 1);
        if (debug) {
            console.log(`${label}[${index}]: [-1, ${beforeIndex}:${agendaItems[beforeIndex].n}] -> ${n}`);
        }
        return n;
    }

    const n = between(agendaItems[beforeIndex].n, agendaItems[afterIndex].n);
    if (debug) {
        console.log(
            `${label}[${index}]: [${beforeIndex}:${agendaItems[beforeIndex].n}, ${afterIndex}:${agendaItems[afterIndex].n}] -> ${n}`,
        );
    }
    return n;
}

export function agendaItemsMapById(meeting: Meeting) {
    const map: Record<string, AgendaItem> = {};
    for (const column of meeting.columns) {
        for (const item of column.items) {
            map[item.id] = item;
        }
    }
    return map;
}
