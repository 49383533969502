import { Flex, Spin } from 'antd';
import React from 'react';

const Loading = () => {
    return (
        <Flex vertical justify={'center'} style={{ padding: '2em' }}>
            <Spin size={'large'} />
        </Flex>
    );
};

export default Loading;
