import { ReactNode } from 'react';
import { Breadcrumb, Space, Typography } from 'antd';
import type { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';

import { useOrg } from 'hooks/useOrg';
import { isString } from 'utils';

import styles from './styles.module.scss';
import ThemeSwitcher from './ThemeSwitcher';
import Profile from './Profile';
import Search from './Search';

const MainHeader = (props: {
    pageTitle: ReactNode | null;
    breadcrumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined;
}) => {
    const org = useOrg();

    const pageTitle = isString(props.pageTitle) ? (
        <Typography.Title style={{ textTransform: 'capitalize' }}>{props.pageTitle}</Typography.Title>
    ) : (
        props.pageTitle
    );

    return (
        <header className={styles.header}>
            <div>
                {pageTitle ? pageTitle : null}
                {props.breadcrumbs ? <Breadcrumb style={{ margin: '0 0 16px 0' }} items={props.breadcrumbs} /> : null}
            </div>
            <Space align={'center'}>
                <Typography.Text strong>{org?.name}</Typography.Text>
                <Search />
                <ThemeSwitcher />
                <Profile />
            </Space>
        </header>
    );
};

export default MainHeader;
