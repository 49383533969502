import { Button, Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Team } from 'types';
import styles from './styles.module.scss';

const GotoTeamButton = (props: { team: Team }) => {
    const navigate = useNavigate();

    return (
        <Flex className={styles.gotoTeam} justify={'center'}>
            <Button onClick={() => navigate(`/teams/${props.team.id}`)}>Go To Team</Button>
        </Flex>
    );
};

export default GotoTeamButton;
