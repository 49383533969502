import { useState } from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import SearchModal from 'components/search/SearchModal';

const Search = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const onSelect = (value: string) => {
        setOpen(false);
        console.log(value);
        navigate(value);
    };

    return (
        <>
            <Button className={styles.button} type={'link'} icon={<SearchOutlined />} onClick={() => setOpen(true)} />
            <SearchModal open={open} onSelect={onSelect} onCancel={() => setOpen(false)} />
        </>
    );
};

export default Search;
