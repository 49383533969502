import React, { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

const DrawerDescription = (
    props: PropsWithChildren<{
        title: string;
        dangerouslySetInnerHTML?: { __html: string };
    }>,
) => {
    return (
        <div className={styles.dd}>
            <h3>{props.title}</h3>
            {props.dangerouslySetInnerHTML ? (
                <div dangerouslySetInnerHTML={props.dangerouslySetInnerHTML} />
            ) : (
                <div>{props.children}</div>
            )}
        </div>
    );
};

export default DrawerDescription;
