import type { AgendaItem, Meeting } from 'types';
import { useContext } from 'react';

import { TeamMeetingContext, type TeamMeetingContextProps } from 'providers/TeamMeetingProvider';
import { nSort } from 'utils';

export function useTeamMeetings() {
    const context = useContext(TeamMeetingContext);
    return context.meetings;
}

export function useTeamMeetingState(): [
    TeamMeetingContextProps['meetings'],
    TeamMeetingContextProps['setTeamMeetings'],
] {
    const context = useContext(TeamMeetingContext);
    return [context.meetings, context.setTeamMeetings];
}

export function useAddMeetingAgendaItem(meeting: Meeting) {
    const context = useContext(TeamMeetingContext);
    return (agendaItem: AgendaItem) => {
        const column = meeting.columns.find((c) => c.id === agendaItem.meeting_column_id);
        if (column) {
            column.items.push(agendaItem);
            column.items.sort(nSort);
            context.setTeamMeetings([...context.meetings]);
        }
    };
}

function filterAgendaItem(meeting: Meeting, agendaItem: AgendaItem) {
    for (const column of meeting.columns) {
        column.items = column.items.filter((i) => i.id !== agendaItem.id);
    }
}

export function useUpdateMeetingAgendaItem(meeting: Meeting) {
    const context = useContext(TeamMeetingContext);

    return (agendaItem: AgendaItem) => {
        filterAgendaItem(meeting, agendaItem);

        let column = meeting.columns.find((c) => c.id === agendaItem.meeting_column_id);
        if (column) {
            column.items.push(agendaItem);
            column.items.sort(nSort);

            context.setTeamMeetings([...context.meetings]);
        }
    };
}

export function useDeleteMeetingAgendaItem(meeting: Meeting) {
    const context = useContext(TeamMeetingContext);

    return (agendaItem: AgendaItem) => {
        let column = meeting.columns.find((c) => c.id === agendaItem.meeting_column_id);
        if (column) {
            column.items = column.items.filter((item) => item.id !== agendaItem.id);
            context.setTeamMeetings([...context.meetings]);
        }
    };
}

export function useMeetingAgendaItem(meeting: Meeting) {
    return {
        addMeetingAgendaItem: useAddMeetingAgendaItem(meeting),
        updateMeetingAgendaItem: useUpdateMeetingAgendaItem(meeting),
        deleteMeetingAgendaItem: useDeleteMeetingAgendaItem(meeting),
    };
}
