import { useState } from 'react';
import { App, Button, Card, Input, Space } from 'antd';

import type { Organization, Person, UserType } from 'types';
import { getDispatchError, hasDispatchError } from 'utils';

import { AppDispatch, useAppDispatch } from 'store';
import UserTypeSelect from './UserTypeSelect';
import { createPerson } from 'features/personSlice';
import { useOrg } from 'hooks/useOrg';

const CreatePersonCard = (props: { org: Organization; busy: boolean; setBusy: (value: boolean) => void }) => {
    const { message } = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();
    const org = useOrg();

    const [email, setEmail] = useState<string>();
    const [userType, setUserType] = useState<UserType>('regular');

    if (!org) return null;

    async function onClick() {
        if (email) {
            const person = { email, user_type: userType, name: email } as Person;
            const res = await dispatch(createPerson(person));
            if (hasDispatchError(res)) {
                message.error(getDispatchError(res));
                return;
            }
            setEmail(undefined);
            message.success(`Person ${email} created.`);
        }
    }

    return (
        <Card title={'Add User'} size={'small'}>
            <Space style={{ width: '100%' }} direction={'vertical'} size={'middle'}>
                <Input placeholder={'Email'} value={email} onChange={(event) => setEmail(event.target.value)} />
                <UserTypeSelect userType={userType} setUserType={setUserType} />
                <Button loading={props.busy} disabled={!email || props.busy} onClick={onClick}>
                    Create
                </Button>
            </Space>
        </Card>
    );
};

export default CreatePersonCard;
