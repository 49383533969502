import type { MouseEvent } from 'react';
import { rgbToHex } from 'utils';

export function canvasPointer(canvas: HTMLCanvasElement, event: MouseEvent): [number, number] {
    const rect = canvas.getBoundingClientRect();

    const x = ((event.clientX - rect.x) / rect.width) * canvas.width;
    const y = ((event.clientY - rect.y) / rect.height) * canvas.height + canvas.height;

    return [x, y];
}

export function getNodeFromEvent<T>(
    event: MouseEvent,
    shadowContext: CanvasRenderingContext2D,
    colorMap: Map<string, T>,
) {
    const p = canvasPointer(shadowContext.canvas, event);
    if (p[0] === Infinity || p[1] === Infinity) {
        return undefined;
    }

    const imageData = shadowContext.getImageData(p[0], p[1], 1, 1).data;
    const hex = rgbToHex(imageData[0], imageData[1], imageData[2]);
    return colorMap.get(hex);
}
