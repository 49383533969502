import React, { useCallback } from 'react';

import { OrgChart } from 'd3-org-chart';
import type { HierarchyNode } from 'd3-hierarchy';

import OrgNoData from './OrgNoData';

import styles from './styles.module.scss';
import { initials } from 'utils';

export type UserDatum = {
    id: string;
    parentId: string | null;
    name: string;
    title: string | undefined;
    imageUrl?: string | null;
};

export type UserNode = HierarchyNode<UserDatum> & { readonly width: number };

function nodeContent(d: HierarchyNode<UserDatum>) {
    const node = d as UserNode;
    const imageUrl = node.data.imageUrl;

    if (!imageUrl) {
        return `
            <div class="circle">${initials(node.data.name)}</div>
            <div class="name">${node.data.name}</div>
            <div class="title">${node.data.title}</div>
        `;
    }

    return `
        <div class="img">
            <img src="${imageUrl}" alt="${node.data.name}" />
        </div>
        <div class="name">${node.data.name}</div>
        <div class="title">${node.data.title}</div>
    `;
}

const OrgChartComponent = (props: { data: UserDatum[]; onClick?: (id: string) => void }) => {
    const onClick = props.onClick;

    const handleRef = useCallback(
        (_node: HTMLDivElement) => {
            new OrgChart<UserDatum>()
                .container('.org-chart-container')
                .data(props.data)
                .nodeWidth((_d) => 200)
                .nodeHeight((_d) => 120)
                .onNodeClick((d) => {
                    if (d.id) {
                        onClick?.(d.id);
                    }
                })
                .nodeContent(nodeContent)
                .render()
                .expandAll()
                .fit();
        },
        [props.data, onClick],
    );

    if (props.data.length === 0) return <OrgNoData />;

    return <div className={'org-chart-container ' + styles.chart} ref={handleRef} />;
};

export default OrgChartComponent;
