import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row, Space, Typography } from 'antd';

import PersonAvatar from 'components/avatar/PersonAvatar';
import CardEmpty from 'components/cards/CardEmpty';
import PeopleInfoCard from 'components/cards/PeopleInfoCard';
import RoleCard from 'components/cards/RoleCard';
import MainContent from 'components/layout/MainContent';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePersonRoles } from 'hooks/roles';
import { usePersonTeams } from 'hooks/teams';
import { usePerson } from 'hooks/usePeople';
import { isPersonSystem } from 'utils';

import styles from './styles.module.scss';
import DeletePersonButton from './DeletePersonButton';
import TeamCard from './TeamCard';

const PersonDetailPage = () => {
    let { personId } = useParams();
    const person = usePerson(personId);
    const currentUser = useCurrentUser();

    const roles = usePersonRoles(person);
    const teams = usePersonTeams(person);

    if (!person || !currentUser) {
        return null;
    }

    return (
        <MainContent>
            <Card>
                <Space align={'center'} size={'large'}>
                    <PersonAvatar person={person} />
                    <Space direction={'vertical'}>
                        <Typography.Title level={2} style={{ margin: 0 }}>
                            {person.name}
                        </Typography.Title>
                        {person.title ? <Typography.Text>{person.title}</Typography.Text> : null}
                    </Space>
                </Space>
            </Card>
            <Card className={styles.card}>
                <div className={'title'}>Roles</div>
                <Row gutter={[16, 16]} wrap>
                    {roles.length > 0 ? (
                        roles.map((role) => (
                            <Col xs={24} lg={12} xl={8} key={role.id}>
                                <RoleCard role={role} showAvatar={false} />
                            </Col>
                        ))
                    ) : (
                        <CardEmpty />
                    )}
                </Row>
            </Card>
            <Card className={styles.card}>
                <div className={'title'}>Teams</div>
                <Row gutter={[16, 16]} wrap>
                    {teams.length > 0 ? (
                        teams.map((team) => (
                            <Col xs={24} lg={12} xl={8} key={team.id}>
                                <TeamCard team={team} />
                            </Col>
                        ))
                    ) : (
                        <CardEmpty />
                    )}
                </Row>
            </Card>
            <PeopleInfoCard person={person} />
            {isPersonSystem(currentUser) ? <DeletePersonButton personId={person.id} /> : null}
        </MainContent>
    );
};

export default PersonDetailPage;
