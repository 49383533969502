import { Col } from 'antd';

import type { Objective, Team } from 'types';

import { useTeamObjectives } from 'hooks/useObjectives';

import ObjectiveCard from './ObjectiveCard';
import AddObjectiveButton from './AddObjectiveButton';
import Tab from './Tab';

const ObjectivesTab = (props: { team: Team; onClick?: (objective: Objective) => void }) => {
    const objectives = useTeamObjectives(props.team);
    return (
        <Tab extra={<AddObjectiveButton team={props.team} />} empty={objectives.length === 0}>
            {objectives.map((objective) => (
                <Col span={12} key={objective.id}>
                    <ObjectiveCard objective={objective} onClick={() => props.onClick?.(objective)} />
                </Col>
            ))}
        </Tab>
    );
};

export default ObjectivesTab;
